// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useState } from "react";
import { Row, Col, Button, Container, Form, InputGroup } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { signMessage } from "../utils/address";
import { hexToAmount } from "../utils/amount";
import "./style.scss";
import { ModalCommon, ModalCommonProps, ModalStatus } from "./base";
import React from "react";

import { selectConfig } from "../data/statusSlice";
import { addToReceipts } from "../layout/layoutSlice";
import { selectL1Account } from "../data/accountSlice";
import {
  AddImageParams,
  ProofSubmitMode,
  ProvePaymentSrc,
  ResetImageParams,
  WithResetContext,
  WithSignature,
  ZkWasmUtil,
} from "zkwasm-service-helper";
import { useResetImageMutation } from "../data/apiSlice";
import { FileInput, TextInput } from "../components/Inputs";

interface ResetImageProps {
  md5: string;
}

export function ResetImageModal(props: ResetImageProps) {
  let account = useAppSelector(selectL1Account);
  let dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectConfig);

  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<ModalStatus>(
    ModalStatus.PreConfirm
  );

  const [contextFile, setContextFile] = React.useState<File | null>(null);
  const [contextBytes, setContextBytes] = React.useState<Uint8Array>(
    new Uint8Array()
  );
  const [circuitSize, setCircuitSize] = React.useState<number>(22);
  const [resetImage, { isLoading, isError, error, data }] =
    useResetImageMutation();

  const [isCreatorImageType, setIsCreatorImageType] =
    React.useState<boolean>(false);
  const [autoSubmitNetworks, setAutoSubmitNetworks] = React.useState<number[]>(
    []
  );

  const handleSelectResetContext = async function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    try {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList.length === 0) {
        setContextFile(null);
        setContextBytes(new Uint8Array());
        return;
      }
      let context = await ZkWasmUtil.browserLoadContextFileAsBytes(fileList[0]);
      setContextBytes(context);
      setContextFile(fileList[0]);
      setMessage("Context file loaded successfully");
    } catch (e: unknown) {
      console.log("error loading context file", e);
      setMessage("Error loading context file: " + e);
      setStatus(ModalStatus.PreConfirm);
      return;
    }
  };

  const resetExistingImage = async function () {
    setMessage("");

    let info: ResetImageParams = {
      md5: props.md5,
      circuit_size: circuitSize,
      user_address: account!.address.toLowerCase(),
      prove_payment_src: isCreatorImageType
        ? ProvePaymentSrc.CreatorPay
        : ProvePaymentSrc.Default,
      auto_submit_network_ids: autoSubmitNetworks,
    };

    if (contextFile) {
      let context_info: WithResetContext = {
        reset_context: contextFile,
        reset_context_md5: ZkWasmUtil.convertToMd5(contextBytes),
      };
      info = {
        ...info,
        ...context_info,
      };
    }

    let msg = ZkWasmUtil.createResetImageMessage(info);
    let signature: string;
    try {
      setMessage("Please sign the application data...");
      signature = await signMessage(msg);
      setMessage("Submitting the application...");
    } catch (e: unknown) {
      console.log("error signing message", e);
      setStatus(ModalStatus.PreConfirm);
      setMessage("Error signing message");
      return;
    }
    let task: WithSignature<ResetImageParams> = {
      ...info,
      signature,
    };
    try {
      let payload = await resetImage(task).unwrap();

      dispatch(addToReceipts(payload));
      setMessage("Image reset task submitted successfully");
    } catch (e) {
      console.log("error resetting image", e);

      setMessage("Error resetting image: " + e);
      return;
    }
  };

  let content = (
    <>
      <Container>
        <Form>
          <Form.Label variant="dark">
            This will reset the image for{" "}
            <span className="highlight p-1">{props.md5}</span>
          </Form.Label>
          <Form.Label variant="dark">
            A new setup of the image will be performed. Old tasks associated
            with this image will be invalidated and marked as Stale. Deployed
            contracts will lose their reference to the old image.
          </Form.Label>

          <Form.Group className="ms-auto">
            <Form.Label variant="dark">
              Auto Submit Proof Network(s):
            </Form.Label>
          </Form.Group>

          <Row>
            {appConfig.chain_info_list.map((chain_info) => {
              if (
                !appConfig.supported_auto_submit_network_ids.includes(
                  chain_info.chain_id
                )
              ) {
                return;
              }
              return (
                <Col xs={2}>
                  <Form.Group
                    className="mb-2"
                    onChange={() => {
                      let tmpNetworks = [...autoSubmitNetworks];

                      if (tmpNetworks.includes(chain_info.chain_id)) {
                        tmpNetworks = tmpNetworks.filter(
                          (id) => id !== chain_info.chain_id
                        );
                        let index = tmpNetworks.find(
                          (id) => id !== chain_info.chain_id
                        );
                        // remove index from array
                        if (index) {
                          tmpNetworks.splice(index, 1);
                        }
                      } else {
                        tmpNetworks.push(chain_info.chain_id);
                      }
                      setAutoSubmitNetworks(tmpNetworks);
                    }}
                  >
                    <div className="ms-auto d-flex ">
                      <Form.Check
                        className="me-2"
                        checked={autoSubmitNetworks.includes(
                          chain_info.chain_id
                        )}
                      />
                      <span className="new-proof-checkbox-label">
                        {chain_info.chain_name}
                      </span>
                    </div>
                  </Form.Group>
                </Col>
              );
            })}
          </Row>

          <Form.Group className="mb-2">
            <Form.Label variant="dark">New Circuit Size:</Form.Label>
            <TextInput
              placeholder="Enter a number for the circuit size"
              autoComplete="off"
              value={circuitSize}
              id="instance-circuitsize"
              name="circuit-size"
              type="number"
              disabled
              onChange={(e) => setCircuitSize(parseInt(e.target.value))}
            ></TextInput>
          </Form.Group>
          <InputGroup className="mb-2 flex-column">
            <Form.Label variant="dark">
              Initial Context{" "}
              <Form.Text className={`text-muted ms-2`}>
                Providing no context file will not reset the context. Upload a
                new context file to reset it.
              </Form.Text>
            </Form.Label>
            <FileInput
              onFileSelect={handleSelectResetContext}
              accept="application/*"
              multiple={false}
            ></FileInput>
          </InputGroup>
          <Form.Group
            className="ms-auto"
            onChange={() => {
              setIsCreatorImageType(!isCreatorImageType);
            }}
          >
            <div className="ms-auto d-flex ">
              <Form.Check className="me-2" />
              <span className="new-proof-checkbox-label">
                Creator Paid Proof
              </span>
            </div>
          </Form.Group>
          <span className="task-fee">
            Expected fee: {BigInt(appConfig.task_fee_list.setup_fee).toString()}{" "}
            Credits
          </span>
        </Form>
      </Container>
    </>
  );
  let childProps: ModalCommonProps = {
    btnLabel: (
      <>
        <div className="d-flex align-items-center reset-btn">
          <span>Reset Image </span>
          <i
            className="bi bi-arrow-repeat ms-1"
            style={{ height: "16px", display: "block" }}
          ></i>
        </div>
      </>
    ),
    title: "Reset Application Image",
    childrenClass: "",
    handleShow: () => {
      let supportedNetworks = appConfig.chain_info_list.filter((chain_info) =>
        appConfig.supported_auto_submit_network_ids.includes(
          chain_info.chain_id
        )
      );
      const initialAutoSubmitNetworks =
        supportedNetworks.length > 0 ? [supportedNetworks[0].chain_id] : [];

      setAutoSubmitNetworks(initialAutoSubmitNetworks);
    },
    handleConfirm: function (): void {
      resetExistingImage();
      setStatus(ModalStatus.Confirmed);
    },
    handleClose: () => {
      // reset the file selected and modal to pre-confirm
      setStatus(ModalStatus.PreConfirm);
      setMessage("");
      setCircuitSize(22);
      setAutoSubmitNetworks([]);
    },
    children: content,
    valid: true,
    message: message,
    status: status,
    confirmLabel: "Confirm",
  };
  return ModalCommon(childProps);
}
