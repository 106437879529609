import React, { CSSProperties, FC, TextareaHTMLAttributes } from "react";
import "./style.scss";

interface CustomInputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  labelClassName?: string;
  labelStyle?: CSSProperties;
}

const CustomInput: FC<CustomInputProps> = ({
  label = "",
  labelClassName = "",
  labelStyle,

  ...inputProps // Spread the remaining input properties
}) => {
  return (
    <div className="text-area-wrapper input-field">
      {label && (
        <label className={`input-label ${labelClassName}`} style={labelStyle}>
          {label}
        </label>
      )}

      <textarea
        {...inputProps} // Spread the input properties to the input element
        className={` ${inputProps.className}`}
      />
    </div>
  );
};

export default CustomInput;
