// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  z-index: 1500;
  max-height: 500px; }
`, "",{"version":3,"sources":["webpack://./src/components/Dropdown/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB,EAAA","sourcesContent":[".overlay {\n  z-index: 1500;\n  max-height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
