import "./style.scss";
import { useState, useEffect } from "react";
import { ApplyFormattingAll } from "./optable.js";
import { Col, Container, Row } from "react-bootstrap";
import ExtraInfo from "./extra";
const InstructionTable = () => {
  useEffect(() => {
    ApplyFormattingAll();
  }, []);
  return (
    <>
      <Container className="mt-4">
        <Row className="text-white mb-2 ">
          <Col className="d-flex align-items-center justify-content-between">
            <h2>Supported WASM Op Codes</h2>
            <div className="d-flex ">
              <span>Unsupported Op = </span>
              <div className="reserved-key ms-2 p-1">Opcode</div>
            </div>
          </Col>
        </Row>
        <table id="opcodes" className="p-1 ">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>_0</th>
              <th>_1</th>
              <th>_2</th>
              <th>_3</th>
              <th>_4</th>
              <th>_5</th>
              <th>_6</th>
              <th>_7</th>
              <th>_8</th>
              <th>_9</th>
              <th>_A</th>
              <th>_B</th>
              <th>_C</th>
              <th>_D</th>
              <th>_E</th>
              <th>_F</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">0_</th>
              <td>unreachable</td>
              <td>nop</td>
              <td>block</td>
              <td>loop</td>
              <td>if</td>
              <td>else</td>
              <td>*try</td>
              <td>*catch</td>
              <td>*throw</td>
              <td>*rethrow</td>
              <td></td>
              <td>end</td>
              <td>br</td>
              <td>br_if</td>
              <td>br_table</td>
              <td>return</td>
            </tr>
            <tr>
              <th scope="row">1_</th>
              <td>call</td>
              <td>call_indirect</td>
              <td>*return_call</td>
              <td>*return_call_indirect</td>
              <td>*call_ref</td>
              <td>*return_call_ref</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>*delegate</td>
              <td>*catch_all</td>
              <td>drop</td>
              <td>select</td>
              <td>*select t</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <th scope="row">2_</th>
              <td>local.get</td>
              <td>local.set</td>
              <td>local.tee</td>
              <td>global.get</td>
              <td>global.set</td>
              <td>*table.get</td>
              <td>*table.set</td>
              <td>&nbsp;</td>
              <td>i32.load</td>
              <td>i64.load</td>
              <td>f32.load</td>
              <td>f64.load</td>
              <td>i32.load8_s</td>
              <td>i32.load8_u</td>
              <td>i32.load16_s</td>
              <td>i32.load16_u</td>
            </tr>
            <tr>
              <th scope="row">3_</th>
              <td>i64.load8_s</td>
              <td>i64.load8_u</td>
              <td>i64.load16_s</td>
              <td>i64.load16_u</td>
              <td>i64.load32_s</td>
              <td>i64.load32_u</td>
              <td>i32.store</td>
              <td>i64.store</td>
              <td>f32.store</td>
              <td>f64.store</td>
              <td>i32.store8</td>
              <td>i32.store16</td>
              <td>i64.store8</td>
              <td>i64.store16</td>
              <td>i64.store32</td>
              <td>memory.size</td>
            </tr>
            <tr>
              <th scope="row">4_</th>
              <td>memory.grow</td>
              <td>i32.const</td>
              <td>i64.const</td>
              <td>f32.const</td>
              <td>f64.const</td>
              <td>i32.eqz</td>
              <td>i32.eq</td>
              <td>i32.ne</td>
              <td>i32.lt_s</td>
              <td>i32.lt_u</td>
              <td>i32.gt_s</td>
              <td>i32.gt_u</td>
              <td>i32.le_s</td>
              <td>i32.le_u</td>
              <td>i32.ge_s</td>
              <td>i32.ge_u</td>
            </tr>
            <tr>
              <th scope="row">5_</th>
              <td>i64.eqz</td>
              <td>i64.eq</td>
              <td>i64.ne</td>
              <td>i64.lt_s</td>
              <td>i64.lt_u</td>
              <td>i64.gt_s</td>
              <td>i64.gt_u</td>
              <td>i64.le_s</td>
              <td>i64.le_u</td>
              <td>i64.ge_s</td>
              <td>i64.ge_u</td>
              <td>f32.eq</td>
              <td>f32.ne</td>
              <td>f32.lt</td>
              <td>f32.gt</td>
              <td>f32.le</td>
            </tr>
            <tr>
              <th scope="row">6_</th>
              <td>f32.ge</td>
              <td>f64.eq</td>
              <td>f64.ne</td>
              <td>f64.lt</td>
              <td>f64.gt</td>
              <td>f64.le</td>
              <td>f64.ge</td>
              <td>i32.clz</td>
              <td>i32.ctz</td>
              <td>i32.popcnt</td>
              <td>i32.add</td>
              <td>i32.sub</td>
              <td>i32.mul</td>
              <td>i32.div_s</td>
              <td>i32.div_u</td>
              <td>i32.rem_s</td>
            </tr>
            <tr>
              <th scope="row">7_</th>
              <td>i32.rem_u</td>
              <td>i32.and</td>
              <td>i32.or</td>
              <td>i32.xor</td>
              <td>i32.shl</td>
              <td>i32.shr_s</td>
              <td>i32.shr_u</td>
              <td>i32.rotl</td>
              <td>i32.rotr</td>
              <td>i64.clz</td>
              <td>i64.ctz</td>
              <td>i64.popcnt</td>
              <td>i64.add</td>
              <td>i64.sub</td>
              <td>i64.mul</td>
              <td>i64.div_s</td>
            </tr>
            <tr>
              <th scope="row">8_</th>
              <td>i64.div_u</td>
              <td>i64.rem_s</td>
              <td>i64.rem_u</td>
              <td>i64.and</td>
              <td>i64.or</td>
              <td>i64.xor</td>
              <td>i64.shl</td>
              <td>i64.shr_s</td>
              <td>i64.shr_u</td>
              <td>i64.rotl</td>
              <td>i64.rotr</td>
              <td>f32.abs</td>
              <td>f32.neg</td>
              <td>f32.ceil</td>
              <td>f32.floor</td>
              <td>f32.trunc</td>
            </tr>
            <tr>
              <th scope="row">9_</th>
              <td>f32.nearest</td>
              <td>f32.sqrt</td>
              <td>f32.add</td>
              <td>f32.sub</td>
              <td>f32.mul</td>
              <td>f32.div</td>
              <td>f32.min</td>
              <td>f32.max</td>
              <td>f32.copysign</td>
              <td>f64.abs</td>
              <td>f64.neg</td>
              <td>f64.ceil</td>
              <td>f64.floor</td>
              <td>f64.trunc</td>
              <td>f64.nearest</td>
              <td>f64.sqrt</td>
            </tr>
            <tr>
              <th scope="row">A_</th>
              <td>f64.add</td>
              <td>f64.sub</td>
              <td>f64.mul</td>
              <td>f64.div</td>
              <td>f64.min</td>
              <td>f64.max</td>
              <td>f64.copysign</td>
              <td>i32.wrap_i64</td>
              <td>i32.trunc_f32_s</td>
              <td>i32.trunc_f32_u</td>
              <td>i32.trunc_f64_s</td>
              <td>i32.trunc_f64_u</td>
              <td>i64.extend_i32_s</td>
              <td>i64.extend_i32_u</td>
              <td>i64.trunc_f32_s</td>
              <td>i64.trunc_f32_u</td>
            </tr>
            <tr>
              <th scope="row">B_</th>
              <td>i64.trunc_f64_s</td>
              <td>i64.trunc_f64_u</td>
              <td>f32.convert_i32_s</td>
              <td>f32.convert_i32_u</td>
              <td>f32.convert_i64_s</td>
              <td>f32.convert_i64_u</td>
              <td>f32.demote_f64</td>
              <td>f64.convert_i32_s</td>
              <td>f64.convert_i32_u</td>
              <td>f64.convert_i64_s</td>
              <td>f64.convert_i64_u</td>
              <td>f64.promote_f32</td>
              <td>i32.reinterpret_f32</td>
              <td>i64.reinterpret_f64</td>
              <td>f32.reinterpret_i32</td>
              <td>f64.reinterpret_i64</td>
            </tr>
            <tr>
              <th scope="row">C_</th>
              <td>*i32.extend8_s</td>
              <td>*i32.extend16_s</td>
              <td>*i64.extend8_s</td>
              <td>*i64.extend16_s</td>
              <td>*i64.extend32_s</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <th scope="row">D_</th>
              <td>*ref.null</td>
              <td>*ref.is_null</td>
              <td>*ref.func</td>
              <td>*ref.as_non_null</td>
              <td>*br_on_null</td>
              <td>*ref.eq</td>
              <td>*br_on_non_null</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            {/* <tr>
              <th scope="row">E_</th>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr> */}
          </tbody>
        </table>
        <div dangerouslySetInnerHTML={{ __html: ExtraInfo }} />
      </Container>
    </>
  );
};

export default InstructionTable;
