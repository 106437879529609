import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setEndpointList } from "../data/endpoint";
import { Container, Form, Modal } from "react-bootstrap";
import { storageKey, selectEndpointList } from "../data/endpoint";
import { TextInput } from "../components/Inputs";
import {
  ModalCommon,
  ModalCommonProps,
  ModalStatus,
  WaitingForResponseBar,
} from "./base";
export default function AddCustomURL() {
  const dispatch = useAppDispatch();
  const endpointList = useAppSelector(selectEndpointList);
  const [newConfig, setNewConfig] = useState<{
    url: string;
    nickname: string;
  }>({ url: "", nickname: "" });
  const [message, setMessage] = useState<string>("");

  //save url to local storage
  const addCustomServerURL = () => {
    if (newConfig.url.length === 0) {
      setMessage("URL cannot be empty");
      return;
    }
    if (newConfig.nickname.length === 0) {
      setMessage("Nickname cannot be empty");
      return;
    }
    if (newConfig.url.endsWith("/")) {
      newConfig.url = newConfig.url.slice(0, -1);
    }
    setMessage("");
    if (endpointList.find((e: any) => e.url === newConfig.url)) {
      setMessage("URL already exists");
      return;
    }
    if (endpointList.find((e: any) => e.nickname === newConfig.nickname)) {
      setMessage("Nickname already exists");
      return;
    }
    //Trim any slashes at the end

    let newEndpointList = [...endpointList, newConfig];
    dispatch(setEndpointList(newEndpointList));
    setMessage("Added new custom server URL - " + newConfig.url);
  };

  let content = (
    <>
      <Container>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label variant="dark">Provider URL:</Form.Label>
            <TextInput
              name="url"
              type="text"
              placeholder="Enter a Provider URL, e.g http://127.0.0.1:8080"
              multiple={false}
              value={newConfig.url}
              onChange={(e) =>
                setNewConfig((prev) => {
                  return { ...prev, url: e.target.value };
                })
              }
            ></TextInput>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label variant="dark">Provider Nickname: </Form.Label>
            <TextInput
              name="nickname"
              type="text"
              placeholder="Enter a Provider nickname, e.g. My Server"
              multiple={false}
              value={newConfig.nickname}
              onChange={(e) =>
                setNewConfig((prev) => {
                  return { ...prev, nickname: e.target.value };
                })
              }
            ></TextInput>
          </Form.Group>
        </Form>
      </Container>
    </>
  );

  let props: ModalCommonProps = {
    btnLabel: (
      <>
        <span style={{ fontSize: "inherit" }}>Add Custom Provider URL</span>
      </>
    ),
    title: "Add Custom Provider URL",
    childrenClass: "",
    handleConfirm: function (): void {
      addCustomServerURL();
    },
    handleClose: () => {
      setNewConfig({ url: "", nickname: "" });
      setMessage("");
    },
    children: content,
    valid: newConfig.url.length > 0,
    message: message,
    status: ModalStatus.PreConfirm,
    confirmLabel: "Confirm",
  };
  return ModalCommon(props);
}
