import { Main } from './layout/Main';

import './App.css';


function App() {
  return (
    <div className="bg-main">
     <Main></Main>
    </div>
  );
}

export default App;
