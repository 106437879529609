import { useState } from "react";
import "./style.scss";

interface PaginationJumpProps {
  totalQueryCount: number;
  maxTasksPerPage: number;
  setCurrentPage: (value: number) => void;
}

export const PaginationJump = ({
  totalQueryCount,
  maxTasksPerPage,
  setCurrentPage,
}: PaginationJumpProps) => {
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  return (
    <div className="pagination-jump me-2 ">
      <span className="d-flex align-items-center me-2">Go to Page</span>
      <input
        type="number"
        placeholder="Page Number"
        value={jumpToPage}
        onChange={(e) => setJumpToPage(parseInt(e.target.value))}
      />
      <button
        className="confirm-btn"
        onClick={() => {
          if (
            jumpToPage < 1 ||
            jumpToPage > Math.ceil(totalQueryCount / maxTasksPerPage)
          )
            return;
          setCurrentPage(jumpToPage);
        }}
      >
        Go
      </button>
    </div>
  );
};
