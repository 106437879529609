import FacebookIcon from "../assets/imgs/fb.png";
import TwitterIcon from "../assets/imgs/twitter.png";
import GithubIcon from "../assets/imgs/github.png";
import logo from "../images/logo.png";
import "bootstrap-icons/font/bootstrap-icons.css";
export default function Footer() {
  return (
    <footer className="footer pt-3 position-relative">
      <div className="container ">
        <div className="row d-flex flex-column">
          <div className="row footer-pill ">
            <div className=" mb-md-0 mb-4 d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <h5 className="footer-heading">
                  <a href="https://www.delphinuslab.com">ZKWASM PAAS Boards</a>
                </h5>
                <span className="footer-heading-info">
                  This board is used to browsing all the tasks submitted to the
                  ZKWASM PAAS network.
                </span>
              </div>

              {/* <a href="/table" className="mb-2">
                Supported WASM Opcodes
              </a> */}
              <a href="https://delphinuslab.com/zk-wasm/" className="more-pill">
                Read more <span className="ion-ios-arrow-round-forward"></span>
              </a>
            </div>
          </div>

          <div className="main-links">
            <div className=" d-flex  align-items-center">
              <div style={{ marginLeft: "40px" }}>
                <img src={logo} className="logo" alt=""></img>
              </div>

              <div className="d-flex flex-column integration-wrapper ">
                <h5 className="sub-heading ">Integration Information</h5>
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column ">
                    <a href="#" className="link">
                      Integration projects with ZKWASM sdk (C, RUST, Assembly
                      Script, etc)
                    </a>

                    <a href="#" className="link">
                      Build web applications with trusted offchain computation
                    </a>
                    <a href="/table" className="link">
                      Supported OpCodes
                    </a>
                  </div>
                  <div className="d-flex flex-column " style={{}}>
                    <div></div>
                    <a href="#" className="link">
                      Integration with ZKWASM PAAS service
                    </a>
                    <a href="#" className="link">
                      Contribute to ZKWASM
                    </a>
                  </div>
                </div>
              </div>
              <div className="socials ms-auto">
                <h5 className="sub-heading">Follow Us</h5>
                <div className="d-flex mt-2">
                  <a href="#" className="py-1 d-flex align-items-center">
                    <img src={FacebookIcon} alt="" />
                  </a>
                  <a
                    href="https://twitter.com/delphinuslab?"
                    className="py-1 d-flex align-items-center"
                  >
                    <img src={TwitterIcon} alt="" />
                  </a>
                  <a
                    href="https://github.com/DelphinusLab"
                    className="py-1 d-flex align-items-center"
                  >
                    <img src={GithubIcon} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center copyright">
          <div className="col-md-6 col-lg-8 text-center">
            <span>
              Copyright ©
              <script>document.write(new Date().getFullYear());</script>2023 All
              rights reserved by{" "}
              <i className="ion-ios-heart" aria-hidden="true"></i> by{" "}
              <a href="https://delphinuslab.com" target="_blank">
                www.delphinuslab.com
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
