// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Form,
  Col,
  Row,
  ListGroup,
  InputGroup,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { signMessage } from "../utils/address";

import "./style.scss";
import {
  ModalCommon,
  ModalCommonProps,
  ModalStatus,
  WaitingForResponseBar,
} from "./base";
import React from "react";

import { addNewWasmImage, loadStatus, selectConfig } from "../data/statusSlice";
import FormData from "form-data";
import { selectL1Account } from "../data/accountSlice";
import {
  AddImageParams,
  WithSignature,
  ZkWasmUtil,
  ProvePaymentSrc,
  WithInitialContext,
} from "zkwasm-service-helper";

import { FileInput, TextInput } from "../components/Inputs";

interface NewWASMImageProps {}

export function NewWASMImage(info: NewWASMImageProps) {
  const dispatch = useAppDispatch();
  let account = useAppSelector(selectL1Account);
  const appConfig = useAppSelector(selectConfig);
  const [fileSelected, setFileSelected] = React.useState<File>(); // also tried <string | Blob>
  const [imageBytes, setImageBytes] = React.useState<Uint8Array>(
    new Uint8Array()
  );
  const [contextFileSelected, setContextFileSelected] = React.useState<File>(); // also tried <string | Blob>
  const [contextBytes, setContextBytes] = React.useState<Uint8Array>(
    new Uint8Array()
  );
  const [validCircuitSize, setValidCircuitSize] = React.useState<boolean>(true);
  const [validWASMFile, setValidWASMFile] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<ModalStatus>(
    ModalStatus.PreConfirm
  );
  //Image upload state
  const [uploadStatus, setUploadStatus] = React.useState<string>(
    "Add an icon for your application. Max size 128x128."
  );

  const [uploadError, setUploadError] = React.useState<boolean>(false);

  // Extra metadata for the image
  const [description_url, setDescriptionURL] = React.useState<string>("");
  const [iconURL, setIconURL] = React.useState<string>("");
  const [circuitSize, setCircuitSize] = React.useState<number>(22);

  const [isCreatorImageType, setIsCreatorImageType] =
    React.useState<boolean>(false);
  const [autoSubmitNetworks, setAutoSubmitNetworks] = React.useState<number[]>(
    []
  );

  async function checkWASMFile(buf: ArrayBuffer) {
    try {
      let m = await WebAssembly.compile(buf);
      let exports = WebAssembly.Module.exports(m);
      // Do we have better way to search a key ?
      for (var entry of exports) {
        if (entry["name"] === "zkmain" && entry["kind"] === "function") {
          return true;
        }
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  interface IVProps {
    valid: boolean;
    file: File | undefined;
  }

  function ValidImage(props: IVProps) {
    console.log(props);
    if (!props.file) {
      return (
        <Form.Text className="text-muted ms-2">
          Select a valid wasm image file (a wasm file contains zkmain as an
          entry).
        </Form.Text>
      );
    } else {
      if (props.valid) {
        return (
          <Form.Text className="text-muted ms-2">
            {props.file!.name} is selected and ready to submit.
          </Form.Text>
        );
      } else {
        return (
          <Form.Text className="text-muted ms-2">
            {props.file!.name} is an invalid wasm image or does not contains
            zkmain as an entry.
          </Form.Text>
        );
      }
    }
  }

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    try {
      const reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        console.log(e.target!.result);
        let buf = e.target!.result! as ArrayBuffer;
        checkWASMFile(buf).then((valid) => {
          setValidWASMFile(valid);
        });
        setImageBytes(new Uint8Array(buf));
      };
      setValidWASMFile(false);
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList.length === 0) {
        // Canceled by user
        setFileSelected(undefined);
        console.log("no file selected");
        return;
      }
      setFileSelected(fileList[0]);
      reader.readAsArrayBuffer(fileList[0]);
    } catch (e: unknown) {
      console.log("error reading file", e);
      setMessage("Error reading file: " + e);
      setStatus(ModalStatus.PreConfirm);
      return;
    }
  };

  const handleSelectInitialContext = async function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    try {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList.length === 0) {
        // Canceled by user
        setContextFileSelected(undefined);
        return;
      }
      setContextFileSelected(fileList[0]);
      let context = await ZkWasmUtil.browserLoadContextFileAsBytes(fileList[0]);
      setMessage("Context file loaded.");
      setContextBytes(context);
      setContextFileSelected(fileList[0]);
    } catch (e: unknown) {
      console.log("error loading context file", e);
      setMessage("Error loading context file: " + e);

      return;
    }
  };

  const addNewImage = async function () {
    if (fileSelected) {
      setMessage("");

      //Sign the data with the users private key

      let md5 = ZkWasmUtil.convertToMd5(
        new Uint8Array(imageBytes as ArrayBuffer)
      );
      let info: AddImageParams = {
        name: fileSelected.name,
        image_md5: md5,
        image: fileSelected,
        user_address: account!.address.toLowerCase(),
        description_url: description_url,
        avator_url: iconURL,
        circuit_size: circuitSize,
        prove_payment_src: isCreatorImageType
          ? ProvePaymentSrc.CreatorPay
          : ProvePaymentSrc.Default,
        auto_submit_network_ids: autoSubmitNetworks,
      };
      // extend the task with context if supplied

      if (contextFileSelected) {
        console.log("context file selected", contextBytes);
        let context_info: WithInitialContext = {
          initial_context: contextFileSelected,
          initial_context_md5: ZkWasmUtil.convertToMd5(contextBytes),
        };
        info = { ...info, ...context_info };
      }

      let msg = ZkWasmUtil.createAddImageSignMessage(info);
      let signature: string;
      try {
        setMessage("Please sign the application data...");
        signature = await signMessage(msg);
        setMessage("Submitting the application...");
      } catch (e: unknown) {
        console.log("error signing message", e);
        setStatus(ModalStatus.PreConfirm);
        setMessage("Error signing message");
        return;
      }
      let task: WithSignature<AddImageParams> = {
        ...info,
        signature,
      };

      dispatch(addNewWasmImage(task))
        .unwrap()
        .then((res) => {
          console.log("Add Image Response", res);

          setMessage(
            "New Setup WASM Image task submitted successfully. Task Id: " +
              res.id
          );
        })
        .catch((err) => {
          console.log("Add Image Error", err);
          setMessage(err.message ? err.message : "Unexpected error occurred.");
          setStatus(ModalStatus.PreConfirm);
        })
        .finally(() =>
          dispatch(
            loadStatus({
              user_address: "",
              md5: "",
              id: "",
              tasktype: "",
              taskstatus: "",
            })
          )
        );
    } else {
      console.log("no image is selected");
    }
  };

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id="button-tooltip" {...props}>
      URLs will be parsed if they start with `https:// or http://`.
    </Tooltip>
  );

  //upload an icon to imgbb
  const [allowUpload, setAllowUpload] = useState(false);
  const uploadIcon = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    if (fileList.length === 0) {
      // Canceled by user
      return;
    }
    let file = fileList[0];

    if (file) {
      try {
        const iconReader = new FileReader();
        iconReader.onload = (e) => {
          let image = new Image();
          image.src = e.target!.result!.toString();
          image.onload = async function () {
            if (image.width > 128 || image.height > 128) {
              setUploadStatus("Please use a smaller icon.");
            } else {
              const formData = new FormData();
              setUploadError(false);
              setUploadStatus("Uploading...");
              // ----IMGUR API --cannot use from localhost
              // formData.append("image", file, file.name);
              // formData.append("type", "file");
              // const res = await axios.post(
              //   "https://api.imgur.com/3/upload",
              //   //@ts-ignore
              //   formData,
              //   {
              //     headers: {
              //       Authorization: `Client-ID ${process.env.REACT_APP_IMGUR_CLIENT_ID}`,
              //     },
              //   }
              // );
              // const { data } = res;
              // console.log(data);
              // setIconURL(data.data.link);
              // //---IMGBB API
              formData.append("key", process.env.REACT_APP_IMGBB_API_KEY!);
              formData.append("image", file, file.name);
              //IMGBB may not be stable either
              console.log("uploading icon for image");
              try {
                const res = await axios.post(
                  "https://api.imgbb.com/1/upload",
                  formData
                );
                const { data } = res;
                console.log(data);
                setIconURL(data.data.url);
                console.log("iconURL", iconURL);
                setUploadStatus("Upload Successful");
              } catch (e) {
                console.log(e);
                setUploadError(true);
                setUploadStatus(
                  "Icon upload failed, check your network status or there may be an issue with the image hosting service."
                );
              }
            }
          };
        };
        iconReader.readAsDataURL(file);
      } catch (err) {
        setUploadStatus("Upload Failed");
        console.log(err);
      }
    }
  };

  let content = (
    <>
      <Container>
        <Form>
          <InputGroup className="mb-2 flex-column">
            <Form.Label variant="dark">
              Image ID (MD5):{" "}
              <ValidImage
                valid={validWASMFile}
                file={fileSelected}
              ></ValidImage>
            </Form.Label>
            <FileInput
              onFileSelect={handleImageChange}
              accept="application/wasm"
              multiple={false}
            ></FileInput>
          </InputGroup>
          <InputGroup className="mb-2 flex-column">
            <Form.Label variant="dark">
              Initial Context{" "}
              <Form.Text className={`text-muted ms-2`}>
                Optional depending on your application
              </Form.Text>
            </Form.Label>
            <FileInput
              onFileSelect={handleSelectInitialContext}
              accept="application/*"
              multiple={false}
            ></FileInput>
          </InputGroup>
          <InputGroup className="mb-2 flex-column">
            <Form.Label variant="dark">
              Application Icon:{" "}
              <Form.Text
                className={`text-muted ms-2 ${uploadError ? "error" : ""}`}
              >
                {iconURL ? `Uploaded icon: ${iconURL}` : uploadStatus}
              </Form.Text>
            </Form.Label>
            <FileInput
              onFileSelect={uploadIcon}
              accept=".png, .jpg, .jpeg"
              multiple={false}
            ></FileInput>
          </InputGroup>

          <Form.Group className="mb-2">
            <Form.Label variant="dark">
              Image Description:{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltip({
                  className: "py-auto",
                })}
              >
                <i className="bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <TextInput
              placeholder="Enter a description and/or a link to your application page"
              autoComplete="off"
              value={description_url}
              id="instance-description"
              name="description"
              type="text"
              onChange={(e) => setDescriptionURL(e.target.value)}
            ></TextInput>
          </Form.Group>

          <Form.Group className="ms-auto">
            <Form.Label variant="dark">
              Auto Submit Proof Network(s):
            </Form.Label>
          </Form.Group>
          <Row>
            {appConfig.chain_info_list.map((chain_info) => {
              if (
                !appConfig.supported_auto_submit_network_ids.includes(
                  chain_info.chain_id
                )
              ) {
                return;
              }
              return (
                <Col xs={2} key={chain_info.chain_id}>
                  <Form.Group
                    className="mb-2"
                    onChange={() => {
                      let tmpNetworks = [...autoSubmitNetworks];

                      if (tmpNetworks.includes(chain_info.chain_id)) {
                        tmpNetworks = tmpNetworks.filter(
                          (id) => id !== chain_info.chain_id
                        );
                        let index = tmpNetworks.find(
                          (id) => id !== chain_info.chain_id
                        );
                        // remove index from array
                        if (index) {
                          tmpNetworks.splice(index, 1);
                        }
                      } else {
                        tmpNetworks.push(chain_info.chain_id);
                      }
                      setAutoSubmitNetworks(tmpNetworks);
                    }}
                  >
                    <div className="ms-auto d-flex ">
                      <Form.Check
                        className="me-2"
                        checked={autoSubmitNetworks.includes(
                          chain_info.chain_id
                        )}
                      />
                      <span className="new-proof-checkbox-label">
                        {chain_info.chain_name}
                      </span>
                    </div>
                  </Form.Group>
                </Col>
              );
            })}
          </Row>

          <Form.Group className="mb-2">
            <Form.Label variant="dark">Circuit Size:</Form.Label>
            <TextInput
              placeholder="Enter a number for the circuit size (18-22)"
              autoComplete="off"
              value={circuitSize}
              id="instance-circuitsize"
              name="circuit-size"
              type="number"
              disabled
              // onChange={(e) => {
              //   let value = parseInt(e.target.value);
              //   setCircuitSize(parseInt(e.target.value));
              //   if (value < 18 || value > 22) {
              //     setMessage("Circuit size must be between 18 and 22");
              //     setValidCircuitSize(false);
              //     return;
              //   }
              //   setMessage("");
              //   setValidCircuitSize(true);
              // }}
            ></TextInput>
          </Form.Group>

          <Form.Group
            className="mb-2"
            onChange={() => {
              setIsCreatorImageType(!isCreatorImageType);
            }}
          >
            <div className="ms-auto d-flex ">
              <Form.Check className="me-2" />
              <span className="new-proof-checkbox-label">
                Creator Paid Proof
              </span>
            </div>
          </Form.Group>

          <span className="task-fee">
            Expected fee: {BigInt(appConfig.task_fee_list.setup_fee).toString()}{" "}
            Credits
          </span>
        </Form>
      </Container>
    </>
  );
  let props: ModalCommonProps = {
    btnLabel: "Create New Application",
    title: "Create New Application Image",
    childrenClass: "",
    handleShow: () => {
      let supportedNetworks = appConfig.chain_info_list.filter((chain_info) =>
        appConfig.supported_auto_submit_network_ids.includes(
          chain_info.chain_id
        )
      );
      const initialAutoSubmitNetworks =
        supportedNetworks.length > 0 ? [supportedNetworks[0].chain_id] : [];
      setAutoSubmitNetworks(initialAutoSubmitNetworks);
    },
    handleConfirm: function (): void {
      addNewImage();
      setStatus(ModalStatus.Confirmed);
    },
    handleClose: () => {
      // reset the file selected and modal to pre-confirm
      setFileSelected(undefined);
      setContextFileSelected(undefined);
      setValidWASMFile(false);
      setStatus(ModalStatus.PreConfirm);
      setMessage("");
      setDescriptionURL("");
      setIconURL("");
      setCircuitSize(22);
      setValidCircuitSize(true);
      setUploadError(false);
      setUploadStatus("Add an icon for your application. Max size 128x128.");
      setAllowUpload(false);
      setAutoSubmitNetworks([]);
    },
    children: content,
    valid: validWASMFile && validCircuitSize,
    message: message,
    status: status,
    confirmLabel: "Confirm",
  };
  return ModalCommon(props);
}
