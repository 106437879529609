import { Container, Pagination } from "react-bootstrap";
import { useGetNodeInfoQuery } from "../../data/apiSlice";
import "./style.scss";
import { useAppSelector } from "../../app/hooks";
import { selectConfig } from "../../data/statusSlice";
import { useState } from "react";
import { PaginationJump } from "../PaginationAmount/pagination-jump";

export default function NodeList() {
  const appConfig = useAppSelector(selectConfig);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const maxNodesPerPage = 25;
  const { data: nodeStats } = useGetNodeInfoQuery({
    start: currentPage * maxNodesPerPage - maxNodesPerPage,
    total: maxNodesPerPage,
  });

  const totalQueryCount = nodeStats?.total || 0;

  const handlePagination = function (page: number) {
    if (page < 1 || page > Math.ceil(totalQueryCount / maxNodesPerPage)) return;

    setCurrentPage(page);
  };
  return (
    <Container style={{ minHeight: "58vh" }}>
      <table className="main-table">
        <thead>
          <tr>
            <th scope="col">Top Node Addresses</th>
            <th scope="col">Successful Tasks</th>
            <th scope="col">Failed Tasks</th>
            <th scope="col">Timed Out Count</th>
            <th scope="col">Total Tasks</th>
            <th scope="col">
              Version - Latest: {appConfig.server_version_info.current_version}
            </th>
          </tr>
        </thead>
        <tbody>
          {nodeStats?.data?.map((node) => {
            return (
              <tr key={node.address}>
                <td
                  scope="row"
                  className="td-address"
                  style={{ padding: "8px 0" }}
                >
                  <a href={`/node?address=${node.address}`}>{node.address}</a>
                </td>
                <td className="td-info">
                  {node.statistics?.successful_tasks || 0}
                </td>
                <td className="td-info">
                  {node.statistics?.failed_tasks || 0}
                </td>
                <td className="td-info">
                  {node.statistics?.timed_out_count || 0}
                </td>
                <td className="td-info">{node.statistics?.total_tasks || 0}</td>
                <td className="td-info">
                  {node.version_info
                    ? isValidNodeVersion(
                        node.version_info.version,
                        appConfig.server_version_info
                          .minimum_supported_node_version
                      )
                      ? node.version_info.version
                      : "Outdated"
                    : "N/A"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-wrapper">
        <PaginationJump
          totalQueryCount={totalQueryCount}
          maxTasksPerPage={maxNodesPerPage}
          setCurrentPage={setCurrentPage}
        ></PaginationJump>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(currentPage - 1)} />

          <Pagination.Item active>
            {currentPage} of {Math.ceil(totalQueryCount / maxNodesPerPage)}
          </Pagination.Item>

          <Pagination.Next onClick={() => handlePagination(currentPage + 1)} />
          <Pagination.Last
            onClick={() =>
              handlePagination(Math.ceil(totalQueryCount / maxNodesPerPage))
            }
          />
        </Pagination>
      </div>
    </Container>
  );
}

// Check the nodes semver against the server's minimum supported version
export function isValidNodeVersion(
  node_version: string,
  server_min_version: string
): boolean {
  let node_version_info = parseSemver(node_version);
  let server_version_info = parseSemver(server_min_version);

  // Check major, minor, patch version numbers
  if (node_version_info.major !== server_version_info.major) {
    return node_version_info.major > server_version_info.major;
  }

  // Compare minor versions
  if (node_version_info.minor !== server_version_info.minor) {
    return node_version_info.minor > server_version_info.minor;
  }

  // Compare patch versions
  return node_version_info.patch >= server_version_info.patch;
}

function parseSemver(semver: string) {
  let semver_parts = semver.split(".");
  return {
    major: semver_parts[0],
    minor: semver_parts[1],
    patch: semver_parts[2],
  };
}
