// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topup-action-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #243237;
  padding: 30px 40px;
  border-radius: 15px; }
  .topup-action-section label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: white; }
  .topup-action-section .input-field {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    border: 2px solid #244e5c;
    color: #c9f2e9; }
    .topup-action-section .input-field:disabled {
      background-color: #2a4852;
      color: #c9f2e9;
      border-color: #2c6574; }
  .topup-action-section .confirm-btn {
    min-width: 75px;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    color: white;
    background: #397b91;
    padding: 5px 30px;
    border-radius: 8px; }
    .topup-action-section .confirm-btn:enabled {
      color: white; }
    .topup-action-section .confirm-btn:hover {
      background: #559ab2 !important;
      color: white; }
    .topup-action-section .confirm-btn:disabled {
      background: #5c666f;
      color: #202932; }
`, "",{"version":3,"sources":["webpack://./src/components/Subscriptions/TopUp/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB,EAAA;EATrB;IAYI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY,EAAA;EAlBhB;IAsBI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,cAAc,EAAA;IA1BlB;MA4BM,yBAAyB;MACzB,cAAc;MACd,qBAAqB,EAAA;EA9B3B;IAkCI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB,EAAA;IAzCtB;MA2CM,YAAY,EAAA;IA3ClB;MA8CM,8BAA8B;MAC9B,YAAY,EAAA;IA/ClB;MAkDM,mBAAmB;MACnB,cAAc,EAAA","sourcesContent":[".topup-action-section {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  background-color: #243237;\n  padding: 30px 40px;\n  border-radius: 15px;\n\n  label {\n    font-family: Inter;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: white;\n  }\n\n  .input-field {\n    font-family: Inter;\n    font-size: 14px;\n    font-weight: 400;\n    border: 2px solid #244e5c;\n    color: #c9f2e9;\n    &:disabled {\n      background-color: #2a4852;\n      color: #c9f2e9;\n      border-color: #2c6574;\n    }\n  }\n  .confirm-btn {\n    min-width: 75px;\n    font-family: Inter;\n    font-weight: 500;\n    font-size: 16px;\n    color: white;\n    background: #397b91;\n    padding: 5px 30px;\n    border-radius: 8px;\n    &:enabled {\n      color: white;\n    }\n    &:hover {\n      background: #559ab2 !important;\n      color: white;\n    }\n    &:disabled {\n      background: #5c666f;\n      color: #202932;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
