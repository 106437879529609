// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px !important; }
  .file-input input {
    display: none;
    background-color: white; }
  .file-input .file-info {
    background-color: #111e2b;
    color: #638891;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    flex: 1 1; }
  .file-input button {
    background-color: #438a8a;
    color: #283935;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/Inputs/FileInput/style.scss"],"names":[],"mappings":"AAIA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EAGnB,6BAA6B,EAAA;EAT/B;IAWI,aAAa;IACb,uBAhB0B,EAAA;EAI9B;IAgBI,yBAlBgC;IAmBhC,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,2BAA2B;IAC3B,8BAA8B;IAC9B,SAAO,EAAA;EAzBX;IA6BI,yBAhC6B;IAiC7B,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,0BAA0B;IAC1B,6BAA6B,EAAA","sourcesContent":["$background-color-input: white !default;\n$background-color-button: #438a8a !default;\n$background-color-file-info: #111e2b !default;\n\n.file-input {\n  font-family: \"Bai Jamjuree\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n\n  //border: 2px solid #b8f4f6;\n  border-radius: 8px !important;\n  input {\n    display: none;\n    background-color: $background-color-input;\n  }\n\n  .file-info {\n    background-color: $background-color-file-info;\n    color: #638891;\n    border: none;\n    padding: 8px 12px;\n    border-radius: 5px;\n    cursor: pointer;\n    user-select: none;\n    border-top-left-radius: 0px;\n    border-bottom-left-radius: 0px;\n    flex: 1;\n  }\n\n  button {\n    background-color: $background-color-button;\n    color: #283935;\n    border: none;\n    padding: 8px 16px;\n    cursor: pointer;\n    border-radius: 5px;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
