import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  InputHTMLAttributes,
} from "react";
import "./style.scss";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  labelStyle?: CSSProperties;
  isInvalid?: boolean;
  isValid?: boolean;
}

const CustomInput: FC<CustomInputProps> = ({
  label = "",
  labelClassName = "",
  labelStyle,
  isInvalid = false,
  isValid = false,

  ...inputProps // Spread the remaining input properties
}) => {
  return (
    <div className="custom-input">
      {label && (
        <label className={`input-label ${labelClassName}`} style={labelStyle}>
          {label}
        </label>
      )}

      <input
        {...inputProps} // Spread the input properties to the input element
        className={`input-field ${inputProps.className} ${
          isInvalid ? "is-invalid" : ""
        } ${isValid ? "is-valid" : ""}`}
      />
    </div>
  );
};

export default CustomInput;
