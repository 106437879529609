import { SubscriptionParams } from "zkwasm-service-helper";
import { formatUnits } from "ethers";

export function getSubscriptionPrice(subscription: SubscriptionParams) {
  let amount = formatUnits(
    subscription.price_per_base_duration,
    subscription.token_data.decimals
  );

  return formatNumberWithCommas(amount);
}

export function getSubscriptionCredits(subscription: SubscriptionParams) {
  let amount = formatUnits(subscription.credited_amount, 0);

  return formatNumberWithCommas(amount);
}

// format a string number to have commas
export function formatNumberWithCommas(input: string): string {
  const number = parseFloat(input);

  if (!isNaN(number) && isFinite(number)) {
    return number.toLocaleString("en-US", { maximumFractionDigits: 20 });
  } else {
    return "Invalid input: not a valid number.";
  }
}
