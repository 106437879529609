import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import {
  addDeployTask,
  addNewWasmImage,
  addProvingTask,
} from "../data/statusSlice";

export interface Receipts {
  id: string;
  md5: string;
}

export interface PanelState {
  status: "home" | "instances";
  dialog: Array<string>;
  receipts: Array<Receipts>;
  waitingForResponse: boolean;
}

const initialState: PanelState = {
  status: "home",
  dialog: [],
  receipts: [],
  waitingForResponse: false,
};

export const panelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    showInstancePanel: (state) => {
      state.status = "instances";
    },
    closeDialog: (state) => {
      state.dialog = [];
    },
    openDialog: (state, d) => {
      state.dialog = d.payload;
    },
    addToReceipts: (state, d) => {
      state.receipts.push(d.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewWasmImage.fulfilled, (state, c) => {
        state.dialog = [];
        state.waitingForResponse = false;
        state.receipts.push(c.payload);
      })
      .addCase(addNewWasmImage.pending, (state, c) => {
        state.waitingForResponse = true;
      })
      .addCase(addNewWasmImage.rejected, (state, c) => {
        state.waitingForResponse = false;
        console.log("rejected", c.payload);
      })
      .addCase(addProvingTask.fulfilled, (state, c) => {
        state.dialog = [];
        state.waitingForResponse = false;
        state.receipts.push(c.payload);
      })
      .addCase(addProvingTask.pending, (state, c) => {
        state.waitingForResponse = true;
      })
      .addCase(addProvingTask.rejected, (state, c) => {
        state.waitingForResponse = false;
      })
      .addCase(addDeployTask.fulfilled, (state, c) => {
        state.dialog = [];
        state.waitingForResponse = false;
        state.receipts.push(c.payload);
      })
      .addCase(addDeployTask.pending, (state, c) => {
        state.waitingForResponse = true;
      })
      .addCase(addDeployTask.rejected, (state, c) => {
        state.waitingForResponse = false;
      });
  },
});

export const { closeDialog, openDialog, addToReceipts } = panelSlice.actions;

export const selectPanel = (state: RootState) => state.panel.status;
export const selectDialog = (state: RootState) => state.panel.dialog;
export const selectReceipts = (state: RootState) => state.panel.receipts;
export const isWaitingForResponse = (state: RootState) =>
  state.panel.waitingForResponse;

export default panelSlice.reducer;
