import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import panelReducer from "../layout/layoutSlice";
import statusReducer from "../data/statusSlice";
import dynamicReducer from "../dynamic/dynamicSlice";
import accountReducer from "../data/accountSlice";
import endpointReducer from "../data/endpoint";
import { zkWASMApi } from "../data/apiSlice";
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        /*
        ignoredActions: ['XXX/XXXX'],
        */
        ignoredActionPaths: [
          "payload.web3",
          "payload.seed",
          "payload.injector",
          "payload.headers",
        ],

        ignoredPaths: [
          "account/fetchAccount/fulfilled",
          "account.l1Account.web3",
          "endpoint.zkWasmServiceHelper",
          "status.config.latest_server_checksum",
          "zkwasmAPI",
        ],
      },
    }).concat(zkWASMApi.middleware),
  reducer: {
    panel: panelReducer,
    status: statusReducer,
    dynamic: dynamicReducer,
    account: accountReducer,
    endpoint: endpointReducer,
    [zkWASMApi.reducerPath]: zkWASMApi.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
