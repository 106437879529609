// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState, useEffect } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import Dropdown from "../components/Dropdown";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import "./style.scss";
import { ModalCommon, ModalCommonProps, ModalStatus } from "./base";
import { TextInput, TextArea, FileInput } from "../components/Inputs";
import React from "react";
import { useGetImageByHashQuery } from "../data/apiSlice";
import {
  ProvingParams,
  ZkWasmUtil,
  WithSignature,
  InputContextType,
  WithCustomInputContextType,
  ProofSubmitMode,
} from "zkwasm-service-helper";
import { selectL1Account } from "../data/accountSlice";
import {
  addProvingTask,
  loadStatus,
  selectTasks,
  selectConfig,
} from "../data/statusSlice";
import { signMessage } from "../utils/address";

interface NewWASMImageProps {}

export function NewProveTask(info: NewWASMImageProps) {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectL1Account);
  const appConfig = useAppSelector(selectConfig);

  const [validInputs, setValidInputs] = React.useState<boolean>(true);
  const [validWitness, setValidWitness] = React.useState<boolean>(true);
  const [md5Selected, setMd5Selected] = React.useState<string>(""); // also tried <string | Blob>
  const [fileSelected, setFileSelected] = React.useState<File>();
  const [contextFile, setContextFile] = React.useState<File>(); // also tried <string | Blob>
  const [showContextDropdown, setShowContextDropdown] = React.useState(false);
  const [selectedInputContextType, setSelectedInputContextType] =
    React.useState<InputContextType>(InputContextType.ImageCurrent);
  const [validMd5, setValidMd5] = React.useState<boolean>(false);
  const [isRefreshed, setIsRefreshed] = React.useState<boolean>(false);
  const [inputs, setInputs] = React.useState<Array<string>>([]); // also tried <string | Blob>
  const [witness, setWitness] = React.useState<Array<string>>([]); // also tried <string | Blob>
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<ModalStatus>(
    ModalStatus.PreConfirm
  );

  const [proofSubmitMode, setProofSubmitMode] = React.useState<ProofSubmitMode>(
    ProofSubmitMode.Manual
  );
  const [showProofSubmitModeDropDown, setShowProofSubmitModeDropDown] =
    React.useState(false);

  const [expectedFee, setExpectedFee] = React.useState<number | undefined>();
  const [expectedFeeMsg, setExpectedFeeMsg] = React.useState<string>("");

  const {
    data: image,
    isLoading: loadingImage,
    isSuccess: loadedImage,
    error,
    refetch,
  } = useGetImageByHashQuery(md5Selected, {
    skip: md5Selected.length !== 32,
  });

  useEffect(() => {
    if (proofSubmitMode === ProofSubmitMode.Manual) {
      setExpectedFee(Number(BigInt(appConfig.task_fee_list.prove_fee)));
      setExpectedFeeMsg("");
      return;
    }
    if (!validMd5 || !image) {
      setExpectedFee(undefined);
      setExpectedFeeMsg("");
      console.error("invalid md5 or image", validMd5, image);
      return;
    }

    const networkList = image.auto_submit_network_ids
      .filter(x => appConfig.supported_auto_submit_network_ids.includes(x));
    const networkListLen =
      networkList === undefined ? 0 : (networkList as number[]).length;
    if (networkListLen === 0 && image.auto_submit_network_ids.length !== 0) {
      const msg = `No specified networks are supported, ${image.auto_submit_network_ids.join(", ")}`;
      setExpectedFee(undefined);
      setExpectedFeeMsg(msg);
      console.error(msg);
      return;
    }

    const multiplier = networkListLen === 0 ? 1 : networkListLen;
    const baseFee = Number(
      BigInt(appConfig.task_fee_list.auto_submit_prove_fee_per_network)
    );
    console.log("base fee", baseFee, "multiplier", multiplier);
    setExpectedFee(baseFee * multiplier);

    const networkListNames = networkList
      .map(x => appConfig.chain_info_list.find(y => y.chain_id === x)?.chain_name);
    setExpectedFeeMsg(`Using networks: ${networkListNames.join(", ")}`);
  }, [proofSubmitMode, validMd5]);

  const handleMd5Change = function (text: string) {
    setValidMd5(false);
    setIsRefreshed(false);
    setMd5Selected(text);
  };

  useEffect(() => {
    if (error) {
      return;
    }
    if (loadedImage) {
      if (image && image.status !== "Received") {
        setValidMd5(true);
      }
      setIsRefreshed(true);
    } else {
      setValidMd5(false);
    }
  }, [image, md5Selected]);

  const handleContextFileChange = async function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    // Check if the file binary is valid array length of u64

    try {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList.length === 0) {
        setFileSelected(undefined);
        return;
      }
      setFileSelected(fileList[0]);
      setMessage(fileList[0].name);
      let context = await ZkWasmUtil.browserLoadContextFileAsBytes(fileList[0]);
      if (ZkWasmUtil.validateContextBytes(context)) {
        setMessage("Context file is loaded");
        setValidInputs(true);
        setContextFile(fileList[0]);
      }
    } catch (e) {
      setMessage("Invalid context file: " + e);
      setValidInputs(false);
      return;
    }
  };

  const handleInputsChange = function (text: string) {
    setValidInputs(true);

    try {
      let inputs = ZkWasmUtil.validateInputs(text);
      setValidInputs(true);
      setInputs(inputs);
      setMessage("");
    } catch (e: unknown) {
      console.log(e);
      setMessage("Invalid public inputs: " + e);
      setValidInputs(false);
      return;
    }
  };

  const handleWitnessChange = function (text: string) {
    setValidWitness(true);

    try {
      let inputs = ZkWasmUtil.validateInputs(text);
      setValidWitness(true);
      setWitness(inputs);
      setMessage("");
    } catch (e: unknown) {
      setMessage("Invalid witness inputs: " + e);
      setValidWitness(false);
      return;
    }
  };

  const addNewProveTask = async function () {
    // Check if file selected when custom context is selected
    if (InputContextType.Custom === selectedInputContextType && !fileSelected) {
      setMessage("Please select a valid file");
      setStatus(ModalStatus.PreConfirm);
      return;
    }

    // convert selected file to bytes
    let contextBytes = new Uint8Array();
    if (fileSelected && selectedInputContextType === InputContextType.Custom) {
      contextBytes = new Uint8Array(await contextFile?.arrayBuffer()!);
    }
    setMessage("");
    console.log("inputs", inputs);
    let info: ProvingParams = {
      user_address: account!.address.toLowerCase(),
      md5: md5Selected,
      public_inputs: inputs,
      private_inputs: witness,
      proof_submit_mode: proofSubmitMode,
    };

    if (selectedInputContextType === InputContextType.Custom) {
      let context_info: WithCustomInputContextType = {
        input_context: contextFile,
        input_context_md5: ZkWasmUtil.convertToMd5(contextBytes),
        input_context_type: selectedInputContextType,
      };
      info = { ...info, ...context_info };
    } else {
      info = { ...info, input_context_type: selectedInputContextType };
    }

    let msgString = ZkWasmUtil.createProvingSignMessage(info);

    let signature: string;
    try {
      setMessage("Waiting for signature...");
      signature = await signMessage(msgString);
      setMessage("Submitting new prove task...");
    } catch (e: unknown) {
      console.log("error signing message", e);
      setStatus(ModalStatus.PreConfirm);
      setMessage("Error signing message");
      return;
    }

    let task: WithSignature<ProvingParams> = {
      ...info,
      signature: signature,
    };

    dispatch(addProvingTask(task))
      .unwrap()
      .then((res) => {
        setStatus(ModalStatus.PostConfirm);
        console.log("new prove task", res);
        setMessage("New prove task submitted successfully. Task Id: " + res.id);
      })
      .catch((err) => {
        console.log("new prove task error", err);
        setMessage(err.message ? err.message : "Unexpected error occurred.");
      })
      .finally(() =>
        dispatch(
          loadStatus({
            user_address: "",
            md5: "",
            id: "",
            tasktype: "",
            taskstatus: "",
          })
        )
      );
  };

  let content = (
    <>
      <Container>
        <Form.Group className="mb-2 position-relative">
          <Form.Label variant="dark">
            Image ID(MD5):{" "}
            <Form.Text className="text-muted ms-2">
              Must be a valid image that has been setup before.
            </Form.Text>
          </Form.Label>
          <TextInput
            placeholder="Enter a valid image ID/MD5"
            autoComplete="off"
            value={md5Selected}
            id="instance-md5"
            name="md5"
            type="text"
            multiple={false}
            isInvalid={!validMd5 && isRefreshed}
            isValid={validMd5 && isRefreshed}
            onChange={(e) => handleMd5Change(e.target.value.trim())}
          ></TextInput>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label variant="dark">
            Public Inputs:{" "}
            <Form.Text className="text-muted ms-2">
              Inputs must have format (0x)[0-f]*:(i64|bytes|bytes-packed) and
              been separated by spaces (eg: 0x12:i64).
            </Form.Text>
          </Form.Label>
          <TextInput
            name="inputs"
            type="text"
            multiple={false}
            onChange={(e) => handleInputsChange(e.target.value)}
          ></TextInput>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label variant="dark">
            Witness Inputs:{" "}
            <Form.Text className="text-muted ms-2">
              Input must be empty or have format
              (0x)[0-f]*:(i64|bytes|bytes-packed) and been separated by spaces
              (eg: 0x12:i64).
            </Form.Text>
          </Form.Label>
          <TextArea
            name="inputs"
            rows={5}
            onChange={(e) => handleWitnessChange(e.target.value)}
          ></TextArea>
        </Form.Group>
        <Form.Group className="mb-2 position-relative">
          <Form.Label variant="dark">
            Context Type:{" "}
            <Form.Text className="text-muted ms-2">
              Context type of the context inputs.
            </Form.Text>
          </Form.Label>
          <TextInput
            placeholder="Select a context type"
            autoComplete="off"
            value={selectedInputContextType}
            id="context-type"
            name="context-type"
            type="text"
            multiple={false}
            readOnly
            onClick={() => setShowContextDropdown(true)}
          ></TextInput>
          {showContextDropdown && (
            <Dropdown
              handleOutsideClick={() => {
                setShowContextDropdown(false);
              }}
            >
              {[
                InputContextType.Custom,
                InputContextType.ImageCurrent,
                InputContextType.ImageInitial,
              ].map((type) => (
                <div
                  key={type}
                  className="dropdown-option text-capitalize"
                  onClick={() => {
                    setSelectedInputContextType(type);
                    setShowContextDropdown(false);
                  }}
                >
                  {type}
                </div>
              ))}
            </Dropdown>
          )}
        </Form.Group>
        {selectedInputContextType === InputContextType.Custom && (
          <InputGroup className="mb-2 flex-column">
            <Form.Label variant="dark">
              Proof Context File:{" "}
              <Form.Text className={`text-muted ms-2`}>
                Custom Context requires a valid context file.
              </Form.Text>
            </Form.Label>

            <FileInput
              onFileSelect={handleContextFileChange}
              accept="application/*"
              multiple={false}
            ></FileInput>
          </InputGroup>
        )}
        <Form.Group className="mb-2 position-relative">
          <Form.Label variant="dark">
            Proof Submit Mode:{" "}
            <Form.Text className="text-muted ms-2">
              Which kind of submit mode for proofs.
            </Form.Text>
          </Form.Label>
          <TextInput
            placeholder="Select a submit mode"
            autoComplete="off"
            value={proofSubmitMode}
            id="proof-submit-mode"
            name="proof-submit-mode"
            type="text"
            multiple={false}
            readOnly
            onClick={() => setShowProofSubmitModeDropDown(true)}
          ></TextInput>
          {showProofSubmitModeDropDown && (
            <Dropdown
              handleOutsideClick={() => {
                setShowProofSubmitModeDropDown(false);
              }}
            >
              <div
                className="dropdown-option text-capitalize"
                onClick={() => {
                  setProofSubmitMode(ProofSubmitMode.Auto);
                  setShowProofSubmitModeDropDown(false);
                }}
              >
                {ProofSubmitMode.Auto}
              </div>
              <div
                className="dropdown-option text-capitalize"
                onClick={() => {
                  setProofSubmitMode(ProofSubmitMode.Manual);
                  setShowProofSubmitModeDropDown(false);
                }}
              >
                {ProofSubmitMode.Manual}
              </div>
            </Dropdown>
          )}
        </Form.Group>
        <span className="task-fee">
          Expected fee:{" "}
          {!expectedFee
            ? "Unknown, select a valid image."
            : `${expectedFee.toString()} Credits. ${expectedFeeMsg}`}
        </span>
      </Container>
    </>
  );

  let props: ModalCommonProps = {
    btnLabel: "Submit Prove Task",
    title: "Create New Prove Task",
    childrenClass: "",
    handleShow: () => {
      if (proofSubmitMode === ProofSubmitMode.Manual) {
        setExpectedFee(Number(BigInt(appConfig.task_fee_list.prove_fee)));
        setExpectedFeeMsg("");
      }
    },
    handleConfirm: function (): void {
      addNewProveTask();
    },
    handleClose: () => {
      // reset the file selected and modal to pre-confirm
      setMd5Selected("");
      setIsRefreshed(false);
      setStatus(ModalStatus.PreConfirm);
      setMessage("");
      setInputs([]);
      setWitness([]);
      setValidInputs(true);
      setValidWitness(true);
      setSelectedInputContextType(InputContextType.ImageCurrent);
    },
    children: content,
    valid: validInputs && validWitness && validMd5,
    message: message,
    status: status,
    confirmLabel: "Confirm",
  };
  return ModalCommon(props);
}
