import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { loadStatistics, selectStatistics } from "../../data/statusSlice";
import "./style.scss";

export default function Statistics() {
  const dispatch = useAppDispatch();
  let statistics = useAppSelector(selectStatistics);
  useEffect(() => {
    dispatch(loadStatistics());
  }, []);
  return (
    <Container
      className="d-flex position-relative px-0"
      style={{ paddingTop: "40px" }}
    >
      <Col className="me-2 py-2 ps-3 statistics text-center d-flex align-items-center justify-content-start">
        <i className="bi bi-cpu"></i>
        <div className="ms-2 d-flex flex-column align-items-start">
          <span className="stat-title">Applications in total</span>
          <span className="stat-value">
            {statistics.totalImages} applications
          </span>
        </div>
      </Col>
      <Col className="mx-2 py-2 ps-3 statistics text-center d-flex align-items-center justify-content-start">
        <i className="bi bi-list-task"></i>
        <div className="ms-2 d-flex flex-column align-items-start">
          <span className="stat-title">Total Tasks Submitted</span>
          <span className="stat-value">{statistics.totalTasks} tasks</span>
        </div>
      </Col>
      <Col className="mx-2 py-2 ps-3 statistics text-center d-flex align-items-center justify-content-start">
        <i className="bi bi-file-binary"></i>
        <div className="ms-2 d-flex flex-column align-items-start">
          <span className="stat-title">Proofs generated in total</span>
          <span className="stat-value">{statistics.totalProofs} proofs</span>
        </div>
      </Col>
      <Col className="ms-2 py-2 ps-3 statistics text-center d-flex align-items-center justify-content-start">
        <i className="bi bi-clock"></i>
        <div className="ms-2 d-flex flex-column align-items-start">
          <span className="stat-title">Average Proving Time</span>
          <span className="stat-value">40 seconds</span>
        </div>
      </Col>
    </Container>
  );
}
