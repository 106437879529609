// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from "react";
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  FormLabel,
  ListGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import "./style.scss";
import React from "react";
import { isWaitingForResponse, selectReceipts } from "../layout/layoutSlice";
import { selectL1Account } from "../data/accountSlice";
import { loadStatus } from "../data/statusSlice";
export interface ModalCommonProps {
  btnLabel: React.ReactNode;
  title: string;
  children?: React.ReactNode;
  childrenClass: string;
  modalClass?: string;
  valid: boolean;
  handleConfirm?: () => void;
  handleShow?: () => void;
  handleClose?: () => void;
  message: string;
  status: ModalStatus;
  confirmLabel?: React.ReactNode;
}

export enum ModalStatus {
  PreConfirm,
  Confirmed,
  PostConfirm,
  Error,
}

export function ModalCommon(props: ModalCommonProps) {
  const [show, setShow] = useState(false);

  let receipts = useAppSelector(selectReceipts);
  let account = useAppSelector(selectL1Account);
  const [receiptCursor, setReceiptCursor] = useState(receipts.length);
  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
    console.log("handleClose");
    setShow(false);
  };
  const handleShow = () => {
    if (props.handleShow) {
      props.handleShow();
    }
    setReceiptCursor(receipts.length);
    setShow(true);
  };

  const Message = () => {
    if (account?.address === undefined) {
      return (
        <div className="modal-msg me-auto d-flex">
          Please connect your wallet before submitting any requests!
        </div>
      );
    }
    return (
      <div className="modal-error-msg me-auto d-flex w-50">{props.message}</div>
    );
  };

  return (
    <>
      <div className="modal-btn" onClick={handleShow}>
        {props.btnLabel}
      </div>

      {show && (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-90w"
          role="dialog"
          className="custom-modal"
        >
          <Modal.Header>{props.title}</Modal.Header>
          <Modal.Body className={`show-grid ${props.modalClass}`}>
            {props.children}
          </Modal.Body>
          <Modal.Footer>
            <Message></Message>

            {/* {receipts.length > receiptCursor && (
            <div>Task Id: {receipts[receiptCursor].id}</div>
          )} */}

            {props.handleConfirm && props.status === ModalStatus.PreConfirm && (
              <Button
                variant="primary"
                style={{ marginRight: "0" }}
                className="confirm-btn me-2"
                disabled={
                  props.valid !== true || account?.address === undefined
                }
                onClick={(e) => {
                  (e.currentTarget as HTMLButtonElement).blur();
                  props.handleConfirm!();
                }}
              >
                {props.confirmLabel}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={handleClose}
              className="close-btn"
            >
              Close
            </Button>
            <WaitingForResponseBar></WaitingForResponseBar>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export function WaitingForResponseBar() {
  let wfr = useAppSelector(isWaitingForResponse);
  if (wfr) {
    return <Spinner animation="border" variant="light"></Spinner>;
  } else {
    return <></>;
  }
}
