import { ZkWasmUtil } from "zkwasm-service-helper";

interface DownloadProps {
  bytes: Uint8Array;
  filename: string;
}

function DownloadJsonButton({ bytes, filename }: DownloadProps) {
  const handleDownloadJson = () => {
    const jsonString = ZkWasmUtil.bytesToJSONString(bytes);
    const blob = new Blob([jsonString], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <button onClick={handleDownloadJson} className="verify-proof-btn">
      Download External Host Table JSON
    </button>
  );
}

export default DownloadJsonButton;
