// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-jump {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: end; }
  .pagination-jump span {
    color: white; }
  .pagination-jump input {
    background-color: #37656f;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 3px 8px;
    max-width: 35%; }
  .pagination-jump input::placeholder {
    color: rgba(255, 255, 255, 0.719); }
  .pagination-jump input::-webkit-outer-spin-button,
  .pagination-jump input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .pagination-jump button {
    margin-left: 0.5rem;
    font-size: 12px;
    padding: 0 8px;
    background: #37656f;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer; }
    .pagination-jump button:hover {
      text-decoration: underline; }
`, "",{"version":3,"sources":["webpack://./src/components/PaginationAmount/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,oBAAoB,EAAA;EAJtB;IAMI,YAAY,EAAA;EANhB;IASI,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,cAAc,EAAA;EAflB;IAkBI,iCAAiC,EAAA;EAlBrC;;IAwBI,wBAAwB;IACxB,SAAS,EAAA;EAzBb;IA6BI,mBAAmB;IACnB,eAAe;IACf,cAAc;IAEd,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,eAAe,EAAA;IArCnB;MAuCM,0BAA0B,EAAA","sourcesContent":[".pagination-jump {\n  font-weight: 400;\n  font-size: 12px;\n  display: flex;\n  justify-content: end;\n  span {\n    color: white;\n  }\n  input {\n    background-color: #37656f;\n    outline: none;\n    border: none;\n    border-radius: 5px;\n    color: white;\n    padding: 3px 8px;\n    max-width: 35%;\n  }\n  input::placeholder {\n    color: rgba(255, 255, 255, 0.719);\n  }\n\n  // Hide the number input arrows\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  button {\n    margin-left: 0.5rem;\n    font-size: 12px;\n    padding: 0 8px;\n\n    background: #37656f;\n    border-radius: 5px;\n    border: none;\n    color: white;\n    cursor: pointer;\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
