// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";
import { Container, ListGroup, Form } from "react-bootstrap";
import { useAppSelector } from "../app/hooks";
import "./style.scss";
import { ModalCommon, ModalCommonProps, ModalStatus } from "./base";

import { selectConfig } from "../data/statusSlice";

import { Inputs, capitalizeFirstLetter } from "../utils/inputs";
import Dropdown from "../components/Dropdown";
import { TextInput } from "../components/Inputs";

import { isError, EthersError } from "ethers";
import { verify_batch_proof, verify_task } from "../utils/proof";

import { VerifierContracts } from "zkwasm-service-helper/dist/mjs/interface/interface";

export interface ProofInfoProps {
  proof: Uint8Array;
  shadow_instances: Uint8Array;
  aux: Uint8Array;
  target_instances: Array<Uint8Array>;
  verifier_contracts: VerifierContracts;
}

export function VerifyBatchProofModal(info: ProofInfoProps) {
  const [message, setMessage] = React.useState("");
  const [disableButton, setDisableButton] = React.useState(false);
  const appConfig = useAppSelector(selectConfig);

  const [selectedChainId, setSelectedChainId] = React.useState<number | null>(
    null
  ); // proof or verify
  const [selectedVerifierAddress, setSelectedVerifierAddress] = React.useState<
    string | null
  >("");

  const [showChainDropdown, setShowChainDropdown] = React.useState(false);

  const [status, setStatus] = React.useState<ModalStatus>(
    ModalStatus.PreConfirm
  );

  // CHeck if the static file checksum is all 0s

  async function testverify() {
    if (!selectedChainId) {
      setMessage("Please select a chain");
      return;
    }

    if (!selectedVerifierAddress) {
      setMessage("Please select a verifier address");
      return;
    }

    try {
      await verify_batch_proof({
        proof: info.proof,
        shadow_instances: info.shadow_instances,
        aux: info.aux,
        target_instances: info.target_instances,
        aggregator_verifier_address: selectedVerifierAddress,
        selectedChainId,
        setMessageCallback: setMessage,
        setDisableButtonCallback: setDisableButton,
        setStatusCallback: setStatus,
      });
    } catch (e) {
      console.error(e);

      // Ethersjs provides a way to check for errors
      // We can handle errors separately based on type
      if (isError(e, "ACTION_REJECTED")) {
        setMessage("User rejected action");
      }
      if (isError(e, "NETWORK_ERROR")) {
        setMessage("Network error sending transaction: " + e.shortMessage);
      }
      if (isError(e, "INSUFFICIENT_FUNDS")) {
        setMessage("Insufficient funds for transaction");
      }
      // Transaction reverted
      if (isError(e, "CALL_EXCEPTION")) {
        setMessage("Transaction reverted: " + e.shortMessage);
      } else {
        let error = e as EthersError;
        console.log("error info", error.info?.name);
        setMessage(
          "Transaction failed with unexpected error. Error code: " +
            error.code +
            "Error message: " +
            error.shortMessage
        );
      }
      setDisableButton(false);
      return;
    }
    setDisableButton(false);
  }

  // Chain name for this batch proof
  const chainName = appConfig.chain_info_list.find(
    (d: any) => d.chain_id == info.verifier_contracts.chain_id
  )?.chain_name;

  let verifyOptions = (
    <>
      <Container className="position-relative " style={{ minHeight: "100px" }}>
        <Form.Label variant="dark">
          Verification Chain:{" "}
          <Form.Text className="text-muted ms-2">
            Select the chain to verify the proof on.
          </Form.Text>
        </Form.Label>
        <TextInput
          placeholder="Select a chain for Verification"
          autoComplete="off"
          value={capitalizeFirstLetter(
            selectedChainId
              ? appConfig.chain_info_list.find(
                  (d: any) => d.chain_id == selectedChainId
                )!.chain_name
              : ""
          )}
          id="verification-chain"
          name="chain"
          type="text"
          multiple={false}
          readOnly
          // onChange={(e) => selectChain(e.target.value)}
          onClick={() => setShowChainDropdown(true)}
        ></TextInput>
        {showChainDropdown ? (
          <Dropdown handleOutsideClick={() => setShowChainDropdown(false)}>
            <div
              className="dropdown-option text-capitalize"
              key={info.verifier_contracts.aggregator_verifier}
              onClick={() => {
                setSelectedChainId(info.verifier_contracts.chain_id);
                setShowChainDropdown(false);
                setDisableButton(false);
                setSelectedVerifierAddress(
                  info.verifier_contracts.aggregator_verifier
                );
              }}
            >
              <span className="text-capitalize">
                {chainName} - {info.verifier_contracts.aggregator_verifier}
              </span>
            </div>
          </Dropdown>
        ) : null}
      </Container>
    </>
  );

  let props: ModalCommonProps = {
    btnLabel: <div className="verify-proof-btn">Verify</div>,
    title: "Proof Information",
    childrenClass: "",
    modalClass: "",
    handleConfirm: function (): void {
      if (selectedChainId != null) {
        testverify();
      }
    },
    handleClose: () => {
      setMessage("");
      setSelectedChainId(null);
      setDisableButton(false);
      setSelectedVerifierAddress("");
      setStatus(ModalStatus.PreConfirm);
    },

    valid: !disableButton,
    status: status,
    children: verifyOptions,
    message: message,
    confirmLabel: "Confirm",
  };
  return ModalCommon(props);
}
