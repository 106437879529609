// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomCard.module.css */
.custom-card {
  /* Add default styles for the Card component here */
  background-color: #1e283000 !important;
  border: 2px solid #34575f !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.custom-header,
.card-header {
  /* Add default styles for the Card.Header component here */
  background-color: rgba(0, 0, 0, 0) !important;
  border-bottom: none !important;
  align-items: center;
  margin: 0 auto;
  padding: 0px 15px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  text-align: center; }

.custom-body {
  /* Add default styles for the Card.Body component here */
  padding: 0px 15px !important; }

.aqua {
  color: #72e9d9 !important; }

.light-blue {
  color: #8edef5 !important; }

.lighter-blue {
  color: #52e3e4 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/Card/style.scss"],"names":[],"mappings":"AAAA,0BAAA;AAEA;EACE,mDAAA;EACA,sCAAsC;EACtC,oCAAoC;EACpC,8BAA8B;EAC9B,wEAAwE;EACxE,qDAAqD,EAAA;;AAGvD;;EAGE,0DAAA;EACA,6CAA6C;EAE7C,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,4BAA4B;EAC5B,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB,EAAA;;AAGpB;EACE,wDAAA;EAEA,4BAA4B,EAAA;;AAG9B;EACE,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB,EAAA","sourcesContent":["/* CustomCard.module.css */\r\n\r\n.custom-card {\r\n  /* Add default styles for the Card component here */\r\n  background-color: #1e283000 !important;\r\n  border: 2px solid #34575f !important;\r\n  border-radius: 10px !important;\r\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\r\n  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);\r\n}\r\n\r\n.custom-header,\r\n.card-header {\r\n  // Override bootstrap card style\r\n  /* Add default styles for the Card.Header component here */\r\n  background-color: rgba(0, 0, 0, 0) !important;\r\n\r\n  border-bottom: none !important;\r\n  align-items: center;\r\n  margin: 0 auto;\r\n  padding: 0px 15px !important;\r\n  font-family: \"Inter\";\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-size: 16px;\r\n  line-height: 34px;\r\n  text-align: center;\r\n}\r\n\r\n.custom-body {\r\n  /* Add default styles for the Card.Body component here */\r\n\r\n  padding: 0px 15px !important;\r\n}\r\n\r\n.aqua {\r\n  color: #72e9d9 !important;\r\n}\r\n.light-blue {\r\n  color: #8edef5 !important;\r\n}\r\n.lighter-blue {\r\n  color: #52e3e4 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
