import {
  VerifyProofParams,
  Task,
  AppConfig,
  Image,
  ZkWasmUtil,
  VerifyBatchProofParams,
} from "zkwasm-service-helper";
import { withBrowserConnector } from "web3subscriber/src/client";
import { DelphinusBrowserConnector } from "web3subscriber/src/provider";
import { ModalStatus } from "../modals/base";

export function Inputs(inputs: Array<string>) {
  return inputs.join(";");
}

type VerifyTaskParams = {
  task: Task;
  image: Image;
  selectedChainId: number;
  aggregator_verifier_address: string;
  setMessageCallback?: (msg: string) => void;
  setDisableButtonCallback?: (disable: boolean) => void;
  setStatusCallback?: (status: ModalStatus) => void;
};

export async function verify_task({
  task,
  selectedChainId,
  aggregator_verifier_address,
  setMessageCallback: setMessage,
  setDisableButtonCallback: setDisableButton,
  setStatusCallback: setStatus,
}: VerifyTaskParams) {
  const msgCallback = setMessage ? setMessage : () => {};
  const disableButtonCallback = setDisableButton ? setDisableButton : () => {};
  const statusCallback = setStatus ? setStatus : () => {};
  msgCallback("Verifying...");
  disableButtonCallback(true);
  statusCallback(ModalStatus.PostConfirm);

  try {
    await withBrowserConnector(async (connector: DelphinusBrowserConnector) => {
      let chainidhex = "0x" + selectedChainId!.toString(16);
      await connector.switchNet(chainidhex);

      let contract = await ZkWasmUtil.composeVerifyContract(
        connector,
        aggregator_verifier_address
      );

      // If the proof has no shadow instances, use the batch instances to try and verify instead.
      // Mostly for legacy purposes.
      let verify_instance =
        task.shadow_instances.length === 0
          ? task.batch_instances
          : task.shadow_instances;

      let proofParams: VerifyProofParams = {
        aggregate_proof: task.proof,
        verify_instance: verify_instance,
        aux: task.aux,
        instances: [task.instances],
      };

      let tx = await ZkWasmUtil.verifyProof(
        contract.getEthersContract(),
        proofParams
      );
      // wait for tx to be mined, can add no. of confirmations as arg
      await tx.wait();

      msgCallback(
        "Verification transaction successful! Transaction hash: " + tx.hash
      );
    });
  } catch (e) {
    console.error(e);
    disableButtonCallback(false);
    throw e;
  }
  disableButtonCallback(false);
}

export type VerifyBatchTaskParams = {
  proof: Uint8Array;
  shadow_instances: Uint8Array;
  aux: Uint8Array;
  target_instances: Array<Uint8Array>;
  selectedChainId: number;
  aggregator_verifier_address: string;
  setMessageCallback?: (msg: string) => void;
  setDisableButtonCallback?: (disable: boolean) => void;
  setStatusCallback?: (status: ModalStatus) => void;
};

export async function verify_batch_proof({
  proof,
  shadow_instances,
  aux,
  target_instances,
  selectedChainId,
  aggregator_verifier_address,
  setMessageCallback: setMessage,
  setDisableButtonCallback: setDisableButton,
  setStatusCallback: setStatus,
}: VerifyBatchTaskParams) {
  const msgCallback = setMessage ? setMessage : () => {};
  const disableButtonCallback = setDisableButton ? setDisableButton : () => {};
  const statusCallback = setStatus ? setStatus : () => {};
  msgCallback("Verifying...");
  disableButtonCallback(true);
  statusCallback(ModalStatus.PostConfirm);

  try {
    await withBrowserConnector(async (connector: DelphinusBrowserConnector) => {
      let chainidhex = "0x" + selectedChainId!.toString(16);
      await connector.switchNet(chainidhex);

      let contract = await ZkWasmUtil.composeVerifyContract(
        connector,
        aggregator_verifier_address
      );

      let proofParams: VerifyProofParams = {
        aggregate_proof: proof,
        verify_instance: shadow_instances,
        aux: aux,
        instances: target_instances,
      };

      let tx = await ZkWasmUtil.verifyProof(
        contract.getEthersContract(),
        proofParams
      );
      // wait for tx to be mined, can add no. of confirmations as arg
      await tx.wait();

      msgCallback(
        "Verification transaction successful! Transaction hash: " + tx.hash
      );
    });
  } catch (e) {
    console.error(e);
    disableButtonCallback(false);
    throw e;
  }
  disableButtonCallback(false);
}
type VerifyAutoSubmitProofParams = {
  proof_info: VerifyBatchProofParams;
  selectedChainId: number;
  aggregator_verifier_address: string;
  setMessageCallback?: (msg: string) => void;
  setDisableButtonCallback?: (disable: boolean) => void;
  setStatusCallback?: (status: ModalStatus) => void;
};

export async function verify_auto_submit_proof({
  proof_info,
  selectedChainId,
  aggregator_verifier_address,
  setMessageCallback: setMessage,
  setDisableButtonCallback: setDisableButton,
  setStatusCallback: setStatus,
}: VerifyAutoSubmitProofParams) {
  const msgCallback = setMessage ? setMessage : () => {};
  const disableButtonCallback = setDisableButton ? setDisableButton : () => {};
  const statusCallback = setStatus ? setStatus : () => {};
  msgCallback("Verifying...");
  disableButtonCallback(true);
  statusCallback(ModalStatus.PostConfirm);

  try {
    await withBrowserConnector(async (connector: DelphinusBrowserConnector) => {
      let chainidhex = "0x" + selectedChainId!.toString(16);
      await connector.switchNet(chainidhex);

      let contract = await ZkWasmUtil.composeBatchVerifierContract(
        connector,
        aggregator_verifier_address
      );

      let tx = await ZkWasmUtil.verifyBatchedProof(
        contract.getEthersContract(),
        proof_info
      );
      // wait for tx to be mined, can add no. of confirmations as arg
      await tx.wait();

      msgCallback(
        "Verification transaction successful! Transaction hash: " + tx.hash
      );
    });
  } catch (e) {
    console.error(e);
    disableButtonCallback(false);
    throw e;
  }
  disableButtonCallback(false);
}
