let ExtraInfo = `<div style="display: none"> 


    <div id="0x00">
        <p>The unreachable instruction causes an unconditional trap.</p>
        <p>A trap immediately aborts execution. Traps cannot be handled by WebAssembly code, but are reported to the outside environment, where they typically can be caught.</p>
        <p><h3>Followed by:</h3>
            <span className="op-imm-args"></span>Note: Any instructions following must be valid. 
        </p>
        <p><h3>Stack:</h3><span className="op-type large">[t<span className="supsub"><sup>∗</sup><sub>1</sub></span>] → [t<span className="supsub"><sup>∗</sup><sub>2</sub></span>]</span></p>
        <p><b>stack-polymorphic</b>: performs an <i>unconditional control transfer</i>.</p>
    </div>
    <div id="0x01">
        <p>The nop instruction does nothing.</p>
        <p><h3>Stack:</h3><span className="op-type">[] → []</span></p>
    </div>
    <div id="0x02">
        <span className="immediate-args">[t<sup>?</sup>]</span>
        <p>the beginning of a block construct, a sequence of instructions with a label at the end.</p>
        <p><h3>Followed by:</h3>
            <ol>
                <li>i8 <i>rt</i> : blocktype — 0x40 = [], 0x7F = [i32], 0x7E = [i64], 0x7D = [f32], 0x7C = [f64]</li>
                <li>instructions</li> 
                <li>0x0B — end</li>
            </ol> 
        </p>
        <p><h3>Stack:</h3><span className="op-type">[] → [t<sup>∗</sup>]</span></p>
        <p>The result type of the instructions must match the blocktype. </p>
        <p>The <i>block</i>, <i>loop</i> and <i>if</i> instructions are structured instructions. They bracket nested sequences of instructions, called blocks, terminated with, or separated by, <i>end</i> or <i>else</i> pseudo-instructions. They must be well-nested.</p>
    </div>
    <div id="0x03">
        <span className="immediate-args">[t<sup>?</sup>]</span>
        <p>a block with a label at the beginning which may be used to form loops</p>
        <p><h3>Followed by:</h3>
            <ol>
                <li>i8 <i>rt</i> : blocktype — 0x40 = [], 0x7F = [i32], 0x7E = [i64], 0x7D = [f32], 0x7C = [f64]</li>
                <li>instructions</li> 
                <li>0x0B — end</li>
            </ol> 
        </p>
        <p><h3>Stack:</h3><span className="op-type">[] → [t<sup>∗</sup>]</span></p>
    </div>
    <div id="0x04">
        <span className="immediate-args">[t<sup>?</sup>]</span>
        <p>the beginning of an if construct with an implicit <i>then</i> block</p>
        <p><h3>Followed by:</h3>
            <ol>
                <li>i8 <i>rt</i> : blocktype — 0x40 = [], 0x7F = [i32], 0x7E = [i64], 0x7D = [f32], 0x7C = [f64]</li>
                <li>instructions<sub>1</sub></li> 
                <li>0x0B — end</li>
            </ol> 
            or
            <ol>
                <li>i8 <i>rt</i> : blocktype</li>
                <li>instructions<sub>1</sub></li> 
                <li>0x05 — else</li>
                <li>instructions<sub>2</sub></li> 
                <li>0x0B — end</li>
            </ol> 
        </p>
        <p>
            <h3>Stack:</h3>
            <span className="op-type">[i32] → [t<sup>∗</sup>]</span><br/>
            <span className="op-type">i32 <i>c</i> → [t<sup>∗</sup>]</span><br/>
            <span className="op-type">if <i>c</i> is non-zero, enter block instructions<sub>1</sub>, else enter block instructions<sub>2</sub></span>
        </p>
    </div>
    <div id="0x05"><p>Marks the else block of an <i>if</i>.</p></div>
    <div id="0x06">
    <p>begins a block which can handle thrown exceptions</p>
    <p><em>Exception Handling Proposal</em></p>
    </div>
    <div id="0x07">
    <p>begins the catch block of the try block</p>
    <p><em>Exception Handling Proposal</em></p>
    </div>
    <div id="0x08">
    <p>Creates an exception defined by the tag and then throws it</p>
    <p><em>Exception Handling Proposal</em></p>
    </div>
    <div id="0x09">
    <p>Pops the exnref on top of the stack and throws it</p>
    <p><em>Exception Handling Proposal</em></p>
    </div>
    <div id="0x0B"><p>Marks the end of a <i>block</i>, <i>loop</i>, <i>if</i>, or function.</p></div>
    <div id="0x0C">
        <span className="immediate-args">l</span>
        <p>Branch to a given label in an enclosing construct.</p>
        <p>Performs an unconditional branch.</p>
        <p>
            <h3>Followed by:</h3><span className="op-imm-args">u32 <i>l</i> : labelidx</span>
        </p>
        <p>Label 0 refers to the innermost structured control instruction enclosing the referring branch instruction, while increasing indices refer to those farther out.</p>
        <p>
            <h3>Stack:</h3><span className="op-type large">[t<span className="supsub"><sup>∗</sup><sub>1</sub></span> t<sup>?</sup>] → [t<span className="supsub"><sup>∗</sup><sub>2</sub></span>]</span>
            <!--  <br/> TODO
            <span className="op-type">labels, <i>val</i> : result → val</span>
            <span className="op-type">vec( label { instr∗ } ), vec( val ) : arguments </span> -->
        </p>
        <p>A branch targeting a <i>block</i> or <i>if</i> behaves like a break statement in most C-like languages, while a branch targeting a <i>loop</i> behaves like a continue statement.</p>
        <p><b>stack-polymorphic</b>: performs an <i>unconditional control transfer</i>.</p>
    </div>
    <div id="0x0D">
        <span className="immediate-args">l</span>
        <p>Performs a conditional branch, branching if i32 <i>c</i> is non-zero.</p>
        <p>Conditionally branch to a given label in an enclosing construct.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args">u32 <i>l</i> : labelidx</span></p>
        <p>
            <h3>Stack:</h3><span className="op-type">[t<sup>?</sup> i32] → [t<sup>?</sup>]</span>
        </p>
    </div>
    <div id="0x0E">
        <span className="immediate-args">l* l</span>
        <p>A jump table which jumps to a label in an enclosing construct.</p>
        <p>Performs an indirect branch through an operand indexing into the label vector that is an immediate to the instruction, or to a default target if the operand is out of bounds.</p>
        <p><h3>Followed by:</h3>
            <ol>
                <li><span className="op-imm-args">u32 <i>l*</i> : vec( labelidx )</span></li>
                <li><span className="op-imm-args">u32 <i>l</i> : labelidx </span></li>
            </ol>
        </p>
        <p>
            <h3>Stack:</h3>
            <span className="op-type large">[t<span className="supsub"><sup>∗</sup><sub>1</sub></span> t<sup>?</sup> i32] → [t<span className="supsub"><sup>∗</sup><sub>2</sub></span>]</span>
            <br/>
        </p>
        <p><b>stack-polymorphic</b>: performs an <i>unconditional control transfer</i>.</p>
    </div>
    <div id="0x0F">
        <p>return zero or more values from this function.</p>
        <p>The return instruction is a shortcut for an unconditional branch to the outermost block, which implicitly is the body of the current function.</p>
        <p><h3>Stack:</h3><span className="op-type large">[t<span className="supsub"><sup>∗</sup><sub>1</sub></span> t<sup>?</sup>] → [t<span className="supsub"><sup>∗</sup><sub>2</sub></span>]</span></p>
        <p><b>stack-polymorphic</b>: performs an <i>unconditional control transfer</i>.</p>
    </div>
    <div id="0x10">
        <span className="immediate-args">x</span>
        <p>The call instruction invokes another function, consuming the necessary arguments from the stack and returning the result values of the call. </p>
        <p><h3>Followed by:</h3><span className="op-imm-args">u32 <i>x</i> : funcidx</span></p>
        <p><h3>Stack:</h3><span className="op-type large">[t<span className="supsub"><sup>∗</sup><sub>1</sub></span>] → [t<span className="supsub"><sup>∗</sup><sub>2</sub></span>]</span></p>
    </div>
    <div id="0x11">
        <span className="immediate-args">x</span>
        <p>The call_indirect instruction calls a function indirectly through an operand indexing into a table.</p>
        <p><h3>Followed by:</h3>
            <ol>
                <li><span className="op-imm-args">u32 <i>x</i> : typeidx</span></li>
                <li><span className="op-imm-args">0x00</span> — zero byte</li>
            </ol>
        </p>
        <p><h3>Stack:</h3><span className="op-type">[t<sup>?</sup> i32] → [t<sup>?</sup>]</span></p>
        <p>In future versions of WebAssembly, the zero byte may be used to index additional tables.</p>
    </div>
    <div id="0x12"> <!-- return_call  -->
        <p>the tail-call version of call</p>
        <p><em>Tail calls proposal</em></p>
    </div>
    <div id="0x13"> <!-- return_call_indirect  -->
        <p>the tail-call version of call</p>
        <p><em>Tail calls proposal</em></p>
    </div>
    <div id="0x14"> <!-- call_ref  -->
        <p>call a function through a ref $t</p>
        <p><em>Typed Function References Proposal</em></p>
    </div>
    <div id="0x15"> <!-- return_call_ref -->
        <!-- todo -->
        <p><em>Typed Function References Proposal</em></p>
    </div>
    
    <div id="0x18">
        <p>begins the delegate block of the try block</p>
        <p><em>Exception Handling Proposal</em></p>
    </div>
    <div id="0x19">
        <p>begins the catch_all block of the try block</p>
        <p><em>Exception Handling Proposal</em></p>
    </div>
    <div id="0x1A">
        <p>The drop instruction simply throws away a single operand.</p>
        <p><h3>Stack:</h3><span className="op-type">[t] → []</span> (value-polymorphic) <!-- https://webassembly.github.io/spec/core/valid/instructions.html#polymorphism --></p>
    </div>
    <div id="0x1B">
        <p>The select instruction selects one of its first two operands based on whether its third operand is zero or not.</p>
        <p><h3>Stack:</h3><span className="op-type">[t t i32] → [t]</span> (value-polymorphic) <!-- https://webassembly.github.io/spec/core/valid/instructions.html#polymorphism --></p>
    </div>
    <div id="0x1C"><!-- select t -->
        <p>Only annotated 'select' can be used with reference types.</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0x20">
        <span className="immediate-args">x</span>
        <p>This instruction gets the value of a variable.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args">u32 <i>x</i> : localidx</span></p>
        <p><h3>Stack:</h3><span className="op-type">[] → [t]</span></p>
        <p>The index space for locals is only accessible inside a function and includes the parameters of that function, which precede the local variables.</p>
        <p>The <i>locals</i> context refers to the list of locals declared in the current function (including parameters), represented by their value type.</p>
    </div>
    <div id="0x21">
        <span className="immediate-args">x</span>
        <p>This instruction sets the value of a variable.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args">u32 <i>x</i> : localidx</span></p>
        <p><h3>Stack:</h3><span className="op-type">[t] → []</span></p>
        <p>The index space for locals is only accessible inside a function and includes the parameters of that function, which precede the local variables.</p>
    </div>
    <div id="0x22">
        <span className="immediate-args">x</span>
        <p>The local.tee instruction is like local.set but also returns its argument.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args">u32 <i>x</i> : localidx</span></p>
        <p><h3>Stack:</h3><span className="op-type">[t] → [t]</span></p>
        <p>The index space for locals is only accessible inside a function and includes the parameters of that function, which precede the local variables.</p>
    </div>
    <div id="0x23">
        <span className="immediate-args">x</span>
        <p>This instruction gets the value of a variable.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args">u32 <i>x</i> : globalidx</span></p>
        <p><h3>Stack:</h3><span className="op-type">[] → [t]</span></p>
        <p>The <i>globals</i> context is the list of globals declared in the current module, represented by their global type.</p>
    </div>
    <div id="0x24">
        <span className="immediate-args">x</span>
        <p>This instruction sets the value of a variable </p>
        <p><h3>Followed by:</h3><span className="op-imm-args">u32 <i>x</i> : globalidx</span></p>
        <p><h3>Stack:</h3><span className="op-type">[t] → []</span></p>
    </div>
    <div id="0x25">
        <p>Access tables</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0x26">
        <p>Access tables</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0x28">
        <span className="immediate-args">m</span>
        <p>load 4 bytes as i32.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args"><i>m</i> : memarg { u32 offset, u32 align }</span></p>
        <p>
            <h3>Stack:</h3>
            <span className="op-type">[i32] → [i32]</span> <br/>
            <span className="op-type">i : address-operand → c : result</span>
        </p>
        <p>Memory is accessed with load and store instructions for the different value types. They all take a memory immediate <i>memarg</i> that contains an address offset and the expected alignment.<!-- (expressed as the exponent of a power of 2). --></p>
        <p>The immediate value memarg.align is an alignment hint about the effective-address. It is a power-of 2 encoded as log2(memarg.align). In practice, its value may be: 0 (8-bit), 1 (16-bit), 2 (32-bit), or (64-bit; used only with wasm64). <!-- https://rsms.me/wasm-intro#addressing-memory --></p>
        <p><code>effective-address = address-operand + memarg.offset <!-- address-operand + offset-immediate --></code></p>
        <p>If memarg.align is incorrect it is considered "misaligned". Misaligned access still has the same behavior as aligned access, only possibly much slower.</p>
    </div>
    <div id="0x29">
        <span className="immediate-args">m</span>
        <p>load 8 bytes as i64.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args"><i>m</i> : memarg { u32 offset, u32 align }</span></p>
        <p>
            <h3>Stack:</h3>
            <span className="op-type">[i32] → [i64]</span>
        </p>
        <p>The static address offset is added to the dynamic address operand, yielding a 33 bit effective address that is the zero-based index at which the memory is accessed. All values are read and written in little endian byte order. A trap results if any of the accessed memory bytes lies outside the address range implied by the memory’s current size.</p>
    </div>
    <div id="0x2A">
        <span className="immediate-args">m</span>
        <p>load 4 bytes as f32.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [f32]</span></p>
        <p>Note: When a number is stored into memory, it is converted into a sequence of bytes in little endian byte order.</p>
    </div>
    <div id="0x2B">
        <span className="immediate-args">m</span>
        <p>load 8 bytes as f64.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [f64]</span></p>
    </div>
    <div id="0x2C">
        <span className="immediate-args">m</span>
        <p>load 1 byte and sign-extend i8 to i32.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
        <p>Integer loads and stores can optionally specify a storage size that is smaller than the bit width of the respective value type. In the case of loads, a sign extension mode sx (s|u) is then required to select appropriate behavior.</p>
    </div>
    <div id="0x2D">
        <span className="immediate-args">m</span>
        <p>load 1 byte and zero-extend i8 to i32</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
    </div>
    <div id="0x2E">
        <span className="immediate-args">m</span>
        <p>load 2 bytes and sign-extend i16 to i32</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
    </div>
    <div id="0x2F">
        <span className="immediate-args">m</span>
        <p>load 2 bytes and zero-extend i16 to i32</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
    </div>
    <div id="0x30">
        <span className="immediate-args">m</span>
        <p>load 1 byte and sign-extend i8 to i64</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    <div id="0x31">
        <span className="immediate-args">m</span>
        <p>load 1 byte and zero-extend i8 to i64</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    <div id="0x32">
        <span className="immediate-args">m</span>
        <p>load 2 bytes and sign-extend i16 to i64</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    <div id="0x33">
        <span className="immediate-args">m</span>
        <p>load 2 bytes and zero-extend i16 to i64</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    <div id="0x34">
        <span className="immediate-args">m</span>
        <p>load 4 bytes and sign-extend i16 to i64</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    <div id="0x35">
        <span className="immediate-args">m</span>
        <p>load 4 bytes and zero-extend i16 to i64</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    <div id="0x36">
        <span className="immediate-args">m</span>
        <p>store 4 bytes (no conversion)</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → []</span></p>
    </div>
    <div id="0x37">
        <span className="immediate-args">m</span>
        <p>store 8 bytes (no conversion)</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i64] → []</span></p>
    </div>
    <div id="0x38">
        <span className="immediate-args">m</span>
        <p>store 4 bytes (no conversion)</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 f32] → []</span></p>
    </div>
    <div id="0x39">
        <span className="immediate-args">m</span>
        <p>store 8 bytes (no conversion)</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 f64] → []</span></p>
    </div>
    <div id="0x3A">
        <span className="immediate-args">m</span>
        <p>wrap i32 to i8 and store 1 byte</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → []</span></p>
    </div>
    <div id="0x3B">
        <span className="immediate-args">m</span>
        <p>wrap i32 to i16 and store 2 bytes</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → []</span></p>
    </div>
    <div id="0x3C">
        <span className="immediate-args">m</span>
        <p>wrap i64 to i8 and store 1 byte</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i64] → []</span></p>
    </div>
    <div id="0x3D">
        <span className="immediate-args">m</span>
        <p>wrap i64 to i16 and store 2 bytes</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i64] → []</span></p>
    </div>
    <div id="0x3E">
        <span className="immediate-args">m</span>
        <p>wrap i64 to i32 and store 4 bytes</p>
        <p><h3>Stack:</h3>
            <span className="op-type">[i32 i64] → []</span><br/>
        </p>
    </div>
    <div id="0x3F">
        <p>The <b>memory.size</b> instruction returns the current size of a memory.</p>
        <p>Operates in units of page size. Each page is 65,536 bytes (64KB).</p>
        <p><h3>Stack:</h3><span className="op-type">[] → [i32]</span></p>
    </div>
    <div id="0x40">
        <p>The memory.grow instruction grows memory by a given delta and returns the previous size, or −1 if enough memory cannot be allocated.</p>
        <p>Operates in units of page size. Each page is 65,536 bytes (64KB).</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
    </div>
    
    <div id="0x41">
        <span className="immediate-args">n</span>
        <p>Push a 32-bit integer value to the stack.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args"><i>n</i> : i32</span></p>
        <p><h3>Stack:</h3><span className="op-type">[] → [i32]</span></p>
    </div>
    <div id="0x42">
        <span className="immediate-args">n</span>
        <p>Push a 64-bit integer value to the stack.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args"><i>n</i> : i64</span></p>
        <p><h3>Stack:</h3><span className="op-type">[] → [i64]</span></p>
    </div>
    <div id="0x43">
        <span className="immediate-args">z</span>
        <p>Push a 32-bit float value to the stack.</p>
        <p><h3>Followed by:</h3><span className="op-imm-args"><i>z</i> : f32</span></p>
        <p><h3>Stack:</h3><span className="op-type">[] → [f32]</span></p>
    </div>
    <div id="0x44">
        <p>Push a 64-bit float value to the stack.</p>
        <span className="immediate-args">z</span>
        <p><h3>Followed by:</h3><span className="op-imm-args"><i>z</i> : f64</span></p>
        <p><h3>Stack:</h3><span className="op-type">[] → [f64]</span></p>
    </div>
    
    <div id="0x45"><p>compare equal to zero.</p><p>Return 1 if operand is zero, 0 otherwise.</p><p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p></div>
    <div id="0x50"><p>compare equal to zero.</p><p>Return 1 if operand is zero, 0 otherwise.</p><p><h3>Stack:</h3><span className="op-type">[i64] → [i32]</span></p></div>
    <div id="0x46">
        <p className="bigsign">==</p>
        <p>sign-agnostic compare equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x51">
        <p className="bigsign">==</p>
        <p>sign-agnostic compare equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x47">
        <p className="bigsign">≠</p>
        <p>sign-agnostic compare unequal</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x52">
        <p className="bigsign">≠</p>
        <p>sign-agnostic compare unequal</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x48">
        <p className="bigsign">&lt;</p>
        <p>signed less than</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x53">
        <p className="bigsign">&lt;</p>
        <p>signed less than</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x49">
        <p className="bigsign">&lt;</p>
        <p>unsigned less than</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x54">
        <p className="bigsign">&lt;</p>
        <p>unsigned less than</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x4A">
        <p className="bigsign">&gt;</p>
        <p>signed greater than</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x55">
        <p className="bigsign">&gt;</p>
        <p>signed greater than</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x4B">
        <p className="bigsign">&gt;</p>
        <p>unsigned greater than</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x56">
        <p className="bigsign">&gt;</p>
        <p>unsigned greater than</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x4C">
        <p className="bigsign">≤</p>
        <p>signed less than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x57">
        <p className="bigsign">≤</p>
        <p>signed less than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x4D">
        <p className="bigsign">≤</p>
        <p>unsigned less than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x58">
        <p className="bigsign">≤</p>
        <p>unsigned less than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x4E">
        <p className="bigsign">≥</p>
        <p>signed greater than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x59">
        <p className="bigsign">≥</p>
        <p>signed greater than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    <div id="0x4F">
        <p className="bigsign">≥</p>
        <p>unsigned greater than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x5A">
        <p className="bigsign">≥</p>
        <p>unsigned greater than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i32]</span></p>
    </div>
    
    <div id="0x5B">
        <p className="bigsign">==</p>
        <p>compare equal</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [i32]</span></p>
    </div>
    <div id="0x61">
        <p className="bigsign">==</p>
        <p>compare equal</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [i32]</span></p>
    </div>
    <div id="0x5C">
        <p className="bigsign">≠</p>
        <p>compare unordered or unequal</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [i32]</span></p>
    </div>
    <div id="0x62">
        <p className="bigsign">≠</p>
        <p>compare unordered or unequal</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [i32]</span></p>
    </div>
    <div id="0x5D">
        <p className="bigsign">&lt;</p>
        <p>less than</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [i32]</span></p>
    </div>
    <div id="0x63">
        <p className="bigsign">&lt;</p>
        <p>less than</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [i32]</span></p>
    </div>
    <div id="0x5E">
        <p className="bigsign">&gt;</p>
        <p>greater than</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [i32]</span></p>
    </div>
    <div id="0x64">
        <p className="bigsign">&gt;</p>
        <p> greater than</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [i32]</span></p>
    </div>
    <div id="0x5F">
        <p className="bigsign">≤</p>
        <p>less than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [i32]</span></p>
    </div>
    <div id="0x65">
        <p className="bigsign">≤</p>
        <p>less than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [i32]</span></p>
    </div>
    <div id="0x60">
        <p className="bigsign">≥</p>
        <p>greater than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [i32]</span></p>
    </div>
    <div id="0x66">
        <p className="bigsign">≥</p>
        <p>greater than or equal</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [i32]</span></p>
    </div>
    
    <div id="0x67">
        <p>sign-agnostic count leading zero bits</p>
        <p>Return the count of leading zero bits in i. All zero bits are considered leading if the value is zero.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
    </div>
    <div id="0x79">
        <p>sign-agnostic count leading zero bits</p>
        <p>Return the count of leading zero bits in i. All zero bits are considered leading if the value is zero.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [i64]</span></p>
    </div>
    <div id="0x68">
        <p>sign-agnostic count trailing zero bits</p>
        <p>Return the count of trailing zero bits in i. All zero bits are considered trailing if the value is zero.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
    </div>
    <div id="0x7A">
        <p>sign-agnostic count trailing zero bits</p>
        <p>Return the count of trailing zero bits in i. All zero bits are considered trailing if the value is zero.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [i64]</span></p>
    </div>
    <div id="0x69">
        <p>sign-agnostic count number of one bits</p>
        <p>Return the count of non-zero bits in i.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i32]</span></p>
    </div>
    <div id="0x7B">
        <p>sign-agnostic count number of one bits</p>
        <p>Return the count of non-zero bits in i.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [i64]</span></p>
    </div>
    
    <div id="0x6A">
        <p>sign-agnostic addition</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x7C">
        <p>sign-agnostic addition</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x6B">
        <p>sign-agnostic subtraction</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x7D">
        <p>sign-agnostic subtraction</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x6C">
        <p>sign-agnostic multiplication, modulo 2<sup>32</sup></p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x7E">
        <p>sign-agnostic multiplication, modulo 2<sup>64</sup></p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x6D">
        <p>signed division (result is truncated toward zero)</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x7F">
        <p>signed division (result is truncated toward zero)</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x6E">
        <p>unsigned division (result is floored)</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x80">
        <p>unsigned division (result is floored)</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x6F">
        <p>signed remainder (result has the sign of the dividend)</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x81">
        <p>signed remainder (result has the sign of the dividend)</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x70">
        <p>unsigned remainder</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x82">
        <p>unsigned remainder</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x71">
        <p>sign-agnostic bitwise <i>and</i>.</p>
        <p>Return the bitwise conjunction of 𝑖1 and 𝑖2.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x83">
        <p>sign-agnostic bitwise <i>and</i>.</p>
        <p>Return the bitwise conjunction of 𝑖1 and 𝑖2.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x72">
        <p>sign-agnostic bitwise <i>inclusive or</i>.</p>
        <p>Return the bitwise disjunction of 𝑖1 and 𝑖2.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x84">
        <p>sign-agnostic bitwise <i>inclusive or</i>.</p>
        <p>Return the bitwise disjunction of 𝑖1 and 𝑖2.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x73">
        <p>sign-agnostic bitwise <i>exclusive or</i>.</p>
        <p>Return the bitwise exclusive disjunction of 𝑖1 and 𝑖2.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x85">
        <p>sign-agnostic bitwise <i>exclusive or</i>.</p>
        <p>Return the bitwise exclusive disjunction of 𝑖1 and 𝑖2.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x74">
        <p>sign-agnostic shift left</p>
        <p>Return the result of shifting i1 left by k bits, modulo 2<sup>32</sup></p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x86">
        <p>sign-agnostic shift left</p>
        <p>Return the result of shifting i1 left by k bits, modulo 2<sup>64</sup></p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x75">
        <p>sign-replicating (arithmetic) shift right</p>
        <p>Return the result of shifting i1 right by k bits, extended with the most significant bit of the original value.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x87">
        <p>sign-replicating (arithmetic) shift right</p>
        <p>Return the result of shifting i1 right by k bits, extended with the most significant bit of the original value.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x76">
        <p>zero-replicating (logical) shift right</p>
        <p>Return the result of shifting i1 right by k bits, extended with 0 bits.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x88">
        <p>zero-replicating (logical) shift right</p>
        <p>Return the result of shifting i1 right by k bits, extended with 0 bits.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x77">
        <p>sign-agnostic rotate left</p>
        <p>Return the result of rotating i1 left by k bits.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x89">
        <p>sign-agnostic rotate left</p>
        <p>Return the result of rotating i1 left by k bits.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    <div id="0x78">
        <p>sign-agnostic rotate right</p>
        <p>Return the result of rotating i1 right by k bits.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32 i32] → [i32]</span></p>
    </div>
    <div id="0x8A">
        <p>sign-agnostic rotate right</p>
        <p>Return the result of rotating i1 right by k bits.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64 i64] → [i64]</span></p>
    </div>
    
    <div id="0x8B">
        <p>absolute value</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f32]</span></p>
    </div>
    <div id="0x99">
        <p>absolute value</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f64]</span></p>
    </div>
    <div id="0x8C">
        <p>negation</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f32]</span></p>
    </div>
    <div id="0x9A">
        <p>negation</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f64]</span></p>
    </div>
    <div id="0x8D">
        <p>ceiling operator</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f32]</span></p>
    </div>
    <div id="0x9B">
        <p>ceiling operator</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f64]</span></p>
    </div>
    <div id="0x8E">
        <p>floor operator</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f32]</span></p>
    </div>
    <div id="0x9C">
        <p>floor operator</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f64]</span></p>
    </div>
    <div id="0x8F">
        <p>round to nearest integer towards zero</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f32]</span></p>
    </div>
    <div id="0x9D">
        <p>round to nearest integer towards zero</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f64]</span></p>
    </div>
    <div id="0x90">
        <p>round to nearest integer, ties to even</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f32]</span></p>
    </div>
    <div id="0x9E">
        <p>round to nearest integer, ties to even</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f64]</span></p>
    </div>
    <div id="0x91">
        <p>square root</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f32]</span></p>
    </div>
    <div id="0x9F">
        <p>square root</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f64]</span></p>
    </div>
    
    <div id="0x92">
        <p>addition</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [f32]</span></p>
    </div>
    <div id="0xA0">
        <p>addition</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [f64]</span></p>
    </div>
    <div id="0x93">
        <p>subtraction</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [f32]</span></p>
    </div>
    <div id="0xA1">
        <p>subtraction</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [f64]</span></p>
    </div>
    <div id="0x94">
        <p>multiplication</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [f32]</span></p>
    </div>
    <div id="0xA2">
        <p>multiplication</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [f64]</span></p>
    </div>
    <div id="0x95">
        <p>division</p>
        <p>partial function: division by 0 is undefined</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [f32]</span></p>
    </div>
    <div id="0xA3">
        <p>division</p>
        <p>partial function: division by 0 is undefined</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [f64]</span></p>
    </div>
    <div id="0x96">
        <p>minimum (binary operator); if either operand is NaN, returns NaN</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [f32]</span></p>
    </div>
    <div id="0xA4">
        <p>minimum (binary operator); if either operand is NaN, returns NaN</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [f64]</span></p>
    </div>
    <div id="0x97">
        <p>maximum (binary operator); if either operand is NaN, returns NaN</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [f32]</span></p>
    </div>
    <div id="0xA5">
        <p>maximum (binary operator); if either operand is NaN, returns NaN</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [f64]</span></p>
    </div>
    <div id="0x98">
        <p>If z1 and z2 have the same sign, then return z1. Else return z1 with negated sign.</p>
        <p><h3>Stack:</h3><span className="op-type">[f32 f32] → [f32]</span></p>
    </div>
    <div id="0xA6">
        <p>If z1 and z2 have the same sign, then return z1. Else return z1 with negated sign.</p>
        <p><h3>Stack:</h3><span className="op-type">[f64 f64] → [f64]</span></p>
    </div>
    
    <div id="0xA7">
        <p>wrap a 64-bit integer to a 32-bit integer.</p>
        <p>Return i modulo 2<sup>32</sup>.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [i32]</span></p>
    </div>
    <div id="0xA8">
        <p>truncate a 32-bit float to a signed 32-bit integer</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [i32]</span></p>
    </div>
    <div id="0xA9">
        <p>truncate a 32-bit float to an unsigned 32-bit integer</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [i32]</span></p>
    </div>
    <div id="0xAA">
        <p>truncate a 64-bit float to a signed 32-bit integer</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [i32]</span></p>
    </div>
    <div id="0xAB">
        <p>truncate a 64-bit float to an unsigned 32-bit integer</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [i32]</span></p>
    </div>
    
    <div id="0xAC">
        <p>extend a signed 32-bit integer to a 64-bit integer.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    <div id="0xAD">
        <p>extend an unsigned 32-bit integer to a 64-bit integer.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [i64]</span></p>
    </div>
    
    <div id="0xAE">
        <p>truncate a 32-bit float to a signed 64-bit integer.</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [i64]</span></p>
    </div>
    <div id="0xAF">
        <p>truncate a 32-bit float to an unsigned 64-bit integer.</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [i64]</span></p>
    </div>
    <div id="0xB0">
        <p>truncate a 64-bit float to a signed 64-bit integer.</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [i64]</span></p>
    </div>
    <div id="0xB1">
        <p>truncate a 64-bit float to an unsigned 64-bit integer.</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [i64]</span></p>
    </div>
    
    <div id="0xB2">
        <p>convert a signed 32-bit integer to a 32-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [f32]</span></p>
    </div>
    <div id="0xB3">
        <p>convert an unsigned 32-bit integer to a 32-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [f32]</span></p>
    </div>
    <div id="0xB4">
        <p>convert a signed 64-bit integer to a 32-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [f32]</span></p>
    </div>
    <div id="0xB5">
        <p>convert an unsigned 64-bit integer to a 32-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [f32]</span></p>
    </div>
    
    <div id="0xB6">
        <p>demote a 64-bit float to a 32-bit float</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [f32]</span></p>
    </div>
    
    <div id="0xB7">
        <p>convert a signed 32-bit integer to a 64-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [f64]</span></p>
    </div>
    <div id="0xB8">
        <p>convert an unsigned 32-bit integer to a 64-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [f64]</span></p>
    </div>
    <div id="0xB9">
        <p>convert a signed 64-bit integer to a 64-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [f64]</span></p>
    </div>
    <div id="0xBA">
        <p>convert an unsigned 64-bit integer to a 64-bit float.</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [f64]</span></p>
    </div>
    
    <div id="0xBB">
        <p>promote a 32-bit float to a 64-bit float</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [f64]</span></p>
    </div>
    
    <div id="0xBC">
        <p>reinterpret the bits of a 32-bit float as a 32-bit integer</p>
        <p><h3>Stack:</h3><span className="op-type">[f32] → [i32]</span></p>
    </div>
    <div id="0xBD">
        <p>reinterpret the bits of a 64-bit float as a 64-bit integer</p>
        <p><h3>Stack:</h3><span className="op-type">[f64] → [i64]</span></p>
    </div>
    <div id="0xBE">
        <p>reinterpret the bits of a 32-bit integer as a 32-bit float</p>
        <p><h3>Stack:</h3><span className="op-type">[i32] → [f32]</span></p>
    </div>
    <div id="0xBF">
        <p>reinterpret the bits of a 64-bit integer as a 64-bit float</p>
        <p><h3>Stack:</h3><span className="op-type">[i64] → [f64]</span></p>
    </div>
    <div id="0xC0"><!-- i32.extend8_s -->
        <p>extend a signed 8-bit integer to a 32-bit integer</p>
        <p><em>Sign-extension operators extension</em></p>
    </div>
    <div id="0xC1"><!-- i32.extend16_s -->
        <p>extend a signed 16-bit integer to a 32-bit integer</p>
        <p><em>Sign-extension operators extension</em></p>
    </div>
    <div id="0xC2"><!-- i64.extend8_s -->
        <p>extend a signed 8-bit integer to a 64-bit integer</p>
        <p><em>Sign-extension operators extension</em></p>
    </div>
    <div id="0xC3"><!-- i64.extend16_s -->
        <p>extend a signed 16-bit integer to a 64-bit integer</p>
        <p><em>Sign-extension operators extension</em></p>
    </div>
    <div id="0xC4"><!-- i64.extend32_s -->
        <p>extend a signed 32-bit integer to a 64-bit integer</p>
        <p><em>Sign-extension operators extension</em></p>
    </div>
    <div id="0xD0">  <!-- ref.null -->
        <p>evaluates to the null reference constant</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0xD1">  <!-- ref.is_null -->
        <p>checks for null</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0xD2">  <!-- ref.func -->
        <p>creates a reference to a given function</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0xD3">  <!-- ref.as_non_null -->
        <p>converts a nullable reference to a non-nullable one or traps if null</p>
        <p><em>Typed Function References Proposal</em></p>
    </div>
    <div id="0xD4"> <!-- br_on_null -->
        <p>converts a nullable reference to a non-nullable one or branches if null</p>
        <p><em>Typed Function References Proposal</em></p>
    </div>
    <div id="0xD5">  <!-- ref.eq  -->
        <p>[eqref eqref] -&gt; [i32]</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    
    <div id="0xD6"> <!-- br_on_non_null -->
        <p>checks for null and branches if present</p>
        <p><em>Typed Function References Proposal, GC Proposal</em></p>
    </div>
    <div id="0xFB">
        <p>Multibyte instructions beginning with the prefix 0xFB.</p>
        <ul>
        <li>Proposal to add garbage collection (GC) support.</li>
        <li>Proposal to add reference-typed strings.</li>
        </ul>
        <p>See table below for full opcodes.</p>
    </div>
    <div id="0xFC">
        <p>Multibyte instructions beginning with the prefix 0xFC.</p>
        <p>Includes opcodes from the following extensions:
        <ul>
        <li>Non-trapping float-to-int conversion</li>
        <li>Bulk Memory Operations</li>
        <li>Reference Types Proposal</li>
        </ul>
        </p>
        <p>See table below for full opcodes.</p>
    </div>
    <div id="0xFD">
        <p>Multibyte instructions beginning with the prefix 0xFD.</p>
        <p>SIMD opcodes (Vector instructions).</p>
        <p>The opcode which follows the prefix uses variable-length integer encoding (LEB128)</p>
        <p>See table below for full opcodes.</p>
    </div>
    <div id="0xFE">
        <p>Multibyte instructions beginning with the prefix 0xFE.</p>
        <p>Threads Proposal for WebAssembly.</p>
        <p>See table below for full opcodes.</p>
    </div>
    
    <!-- GC proposal -->
    <div id="0xFB01">
        <p>allocates a structure with canonical RTT (runtime type) and initialises its fields with given values</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB02">
        <p>allocates a structure of type $t with canonical RTT (runtime type) and initialises its fields with default values</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB03">
        <p>reads field <code>i</code> from a structure</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB06">
        <p>writes field <code>i</code> of a structure</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB11">
        <p>allocates an array with canonical RTT (runtime type)</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB12">
        <p>allocates an array with canonical RTT (runtime type) and initialises its fields with the default value</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB13">
        <p>reads an element from an array</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB16">
        <p>writes an element to an array</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB17">
        <p>inquires the length of an array</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB19">
        <p>allocates an array with canonical RTT (runtime type) of fixed size and initialises it from operands</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB1B">
        <p>allocates an array with canonical RTT (runtime type) and initialises it from a data segment</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB1C">
        <p>allocates an array with canonical RTT (runtime type) and initialises it from an element segment</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB20">
        <p>creates an i31ref from a 32 bit value, truncating high bit</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB21"> <!-- i31.get_s -->
        <p>extracts the value, sign-extending</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB22"> <!-- i31.get_u -->
        <p>extracts the value, zero-extending</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB40"> 
        <p>checks whether a reference has a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB40"> 
        <p>checks whether a reference has a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB41"> 
        <p>tries to convert to a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB42">
        <p>branches if a reference has a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB43">
        <p>branches if a reference does not have a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB48"> 
        <p>checks whether a reference has a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB49"> 
        <p>tries to convert to a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB4A">
        <p>branches if a reference has a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB4B">
        <p>branches if a reference does not have a given heap type</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB70">
        <p>converts an external value into the internal representation</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    <div id="0xFB71">
        <p>converts an internal value into the external representation</p>
        <p><em>Garbage Collection Proposal</em></p>
    </div>
    
    <!-- FC extensions -->
    <div id="0xFC00">
        <p>saturating form of i32.trunc_f32_s</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC01">
        <p>saturating form of i32.trunc_f32_u</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC02">
        <p>saturating form of i32.trunc_f64_s</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC03">
        <p>saturating form of i32.trunc_f64_u</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC04">
        <p>saturating form of i64.trunc_f32_s</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC05">
        <p>saturating form of i64.trunc_f32_u</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC06">
        <p>saturating form of i64.trunc_f64_s</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC07">
        <p>saturating form of i64.trunc_f64_u</p>
        <p><em>Non-trapping float-to-int Conversion Proposal</em></p>
    </div>
    <div id="0xFC08">
        <p>copy from a passive data segment to linear memory</p>
        <p><em>Bulk Memory Operations</em></p>
    </div>
    <div id="0xFC09">
        <p>prevent further use of passive data segment</p>
        <p><em>Bulk Memory Operations</em></p>
    </div>
    <div id="0xFC0F">
        <p>manipulate the size of a table</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0xFC10">
        <p>manipulate the size of a table</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0xFC11">
        <p>fills a range in a table with a value</p>
        <p><em>Reference Types Proposal</em></p>
    </div>
    <div id="0xFC0A">
        <p>copy from one region of linear memory to another region</p>
        <p><em>Bulk Memory Operations</em></p>
    </div>
    <div id="0xFC0B">
        <p>fill a region of linear memory with a given byte value</p>
        <p><em>Bulk Memory Operations</em></p>
    </div>
    <div id="0xFC0C">
        <p>copy from a passive element segment to a table</p>
        <p><em>Bulk Memory Operations</em></p>
    </div>
    <div id="0xFC0D">
        <p>prevent further use of a passive element segment</p>
        <p><em>Bulk Memory Operations</em></p>
    </div>
    <div id="0xFC0E">
        <p>copy from one region of a table to another region</p>
        <p><em>Bulk Memory Operations</em></p>
    </div>
    
    <!-- 0xFD SIMD instructions, ordered like "https://doc.rust-lang.org/stable/core/arch/wasm/index.html" -->
    
    <div id="0xFDE0">
        <p>Calculates the absolute value of each lane of a 128-bit vector interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFDE4">
        <p>Lane-wise addition of two 128-bit vectors interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFD67">
        <p>Lane-wise rounding to the nearest integral value not smaller than the input.</p>
    </div>
    <div id="0xFDFB">
        <p>Converts a 128-bit vector interpreted as four 32-bit unsigned integers into a 128-bit vector of four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFDFA">
        <p>Converts a 128-bit vector interpreted as four 32-bit signed integers into a 128-bit vector of four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFD5E">
        <p>Conversion of the two double-precision floating point lanes to two lower single-precision lanes of the result. The two higher lanes of the result are initialized to zero. If the conversion result is not representable as a single-precision floating point number, it is rounded to the nearest-even representable number.</p>
    </div>
    <div id="0xFDE7">
        <p>Lane-wise division of two 128-bit vectors interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFD41">
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the corresponding input elements were equal, or all zeros otherwise.</p>
    </div>
    <div id="0xFD1F">
        <p>Extracts a lane from a 128-bit vector interpreted as 4 packed f32 numbers.</p>
        <p>Extracts the scalar value of lane specified fn the immediate mode operand N from a. If N is out of bounds then it is a compile time error.</p>
        <!-- todo: variables: https://doc.rust-lang.org/stable/core/arch/wasm/fn.f32x4_extract_lane.html -->
    </div>
    <div id="0xFD68">
        <p>Lane-wise rounding to the nearest integral value not greater than the input.</p>
    </div>
    <div id="0xFD46">
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is greater than [or equal] the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD44">
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is greater than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD45">
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is less than [or equal] the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD43">
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is less than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFDE9">
        <p>Calculates the lane-wise [maximum] of two 128-bit vectors interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFDE8">
        <p>Calculates the lane-wise minimum of two 128-bit vectors interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFDE6">
        <p>Lane-wise multiplication of two 128-bit vectors interpreted as four 32-bit floating point numbers.</p>
    </div>
    
    <div id="0xFD42">
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the corresponding input elements were not equal, or all zeros otherwise.</p>
    </div>
    <div id="0xFD6A">
        <p>Lane-wise rounding to the nearest integral value; if two values are equally near, rounds to the even one.</p>
    </div>
    <div id="0xFDE1"> <!-- f32x4.neg -->
        <p>Negates each lane of a 128-bit vector interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFDEB">
        <p>Lane-wise maximum value, defined as a &lt; b ? b : a</p>
    </div>
    <div id="0xFDEA">
        <p>Lane-wise minimum value, defined as b &lt; a ? b : a</p>
    </div>
    <div id="0xFD20"> <!-- f32x4.replace_lane -->
        <p>Replaces a lane from a 128-bit vector interpreted as 4 packed f32 numbers.</p>
        <p>Rust: <code>fn f32x4_replace_lane<const N: usize>(a: v128, val: f32) -> v128</code></p>
        <p>Replaces the scalar value of lane specified fn the immediate mode operand <code>N</code> from <code>a</code>. If <code>N</code> is out of bounds then it is a compile time error.</p>
    </div>
    <div id="0xFD13"> <!-- f32x4.splat -->
        <p>Creates a vector with identical lanes.</p>
        <p>Constructs a vector with x replicated to all 4 lanes.</p>
    </div>
    <div id="0xFDE3"> <!-- f32x4.sqrt -->
        <p>Calculates the square root of each lane of a 128-bit vector interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFDE5"> <!-- f32x4.sub -->
        <p>Lane-wise subtraction of two 128-bit vectors interpreted as four 32-bit floating point numbers.</p>
    </div>
    <div id="0xFD69"> <!-- f32x4.trunc -->
        <p>Lane-wise rounding to the nearest integral value with the magnitude not larger than the input.</p>
    </div>
    <div id="0xFDEC"> <!-- f64x2.abs -->
        <p>Calculates the absolute value of each lane of a 128-bit vector interpreted as two 64-bit floating point numbers.</p>
    </div>
    <div id="0xFDF0"> <!-- f64x2.add -->
        <p>Lane-wise add of two 128-bit vectors interpreted as two 64-bit floating point numbers.</p>
    </div>
    <div id="0xFD74"> <!-- f64x2.ceil -->
        <p>Lane-wise rounding to the nearest integral value not smaller than the input.</p>
    </div>
    <div id="0xFDFE"> <!-- f64x2.convert_low_i32x4 [_s]-->
        <p>Lane-wise conversion from signed integer to floating point.</p>
    </div>
    <div id="0xFDFF"> <!-- f64x2.convert_low_u32x4 [f64x2.convert_low_i32x4_u]-->
        <p>Lane-wise conversion from unsigned integer to floating point.</p>
    </div>
    <div id="0xFDF3"> <!-- f64x2.div -->
        <p>Lane-wise divide of two 128-bit vectors interpreted as two 64-bit floating point numbers.</p>
    </div>
    <div id="0xFD47"> <!-- f64x2.eq -->
        <p>Compares two 128-bit vectors as if they were two vectors of 2 sixty-four-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the corresponding input elements were equal, or all zeros otherwise.</p>
    </div>
    <div id="0xFD21"> <!-- f64x2.extract_lane -->
        <p>Extracts a lane from a 128-bit vector interpreted as 2 packed f64 numbers.</p>
        <p>Extracts the scalar value of lane specified fn the immediate mode operand N from 'a'. If 'N' [is] out of bounds then it is a compile time error.</p>
    </div>
    <div id="0xFD75"> <!-- f64x2.floor -->
        <p>Lane-wise rounding to the nearest integral value not greater than the input.</p>
    </div>
    <div id="0xFD4C"> <!-- f64x2.ge -->
        <p>Compares two 128-bit vectors as if they were two vectors of 2 sixty-four-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is greater than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD4A"> <!-- f64x2.gt -->
        <p>Compares two 128-bit vectors as if they were two vectors of 2 sixty-four-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is greater than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD4B"> <!-- f64x2.le -->
        <p>Compares two 128-bit vectors as if they were two vectors of 2 sixty-four-bit floating point numbers</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is less than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD49"> <!-- f64x2.lt -->
        <p>Compares two 128-bit vectors as if they were two vectors of 2 sixty-four-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is less than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFDF5"> <!-- f64x2.max -->
        <p>Calculates the lane-wise maximum of two 128-bit vectors interpreted as two 64-bit floating point numbers.</p>
    </div>
    <div id="0xFDF4"> <!-- f64x2.min -->
        <p>Calculates the lane-wise minimum of two 128-bit vectors interpreted as two 64-bit floating point numbers.
    </p>
    </div>
    <div id="0xFDF2"> <!-- f64x2.mul -->
        <p>Lane-wise multiply of two 128-bit vectors interpreted as two 64-bit floating point numbers.
    </p>
    </div>
    <div id="0xFD48"> <!-- f64x2.ne -->
        <p>Compares two 128-bit vectors as if they were two vectors of 2 sixty-four-bit floating point numbers.</p>
        <p>Returns a new vector where each lane is all ones if the corresponding input elements were not equal, or all zeros otherwise.</p>
    </div>
    <div id="0xFD94"> <!-- f64x2.nearest -->
        <p>Lane-wise rounding to the nearest integral value; if two values are equally near, rounds to the even one.</p>
    </div>
    <div id="0xFDED"> <!-- f64x2.neg -->
        <p>Negates each lane of a 128-bit vector interpreted as two 64-bit floating point numbers.</p>
    </div>
    <div id="0xFDF7"> <!-- f64x2.pmax -->
        <p>Lane-wise maximum value</p> <!-- todo -->
    </div>
    <div id="0xFDF6"> <!-- f64x2.pmin -->
        <p>Lane-wise minimum value</p>
    </div>
    <div id="0xFD5F"> <!-- f64x2.promote_low_f32x4 -->
        <p>Conversion of the two lower single-precision floating point lanes to the two double-precision lanes of the result.</p>
    </div>
    <div id="0xFD22"> <!-- f64x2.replace_lane -->
        <p>Replaces a lane from a 128-bit vector interpreted as 2 packed f64 numbers.</p>
        <p>Replaces the scalar value of lane specified in the immediate mode operand N from 'a'. If N is out of bounds then it is a compile time error.</p>
    </div>
    <div id="0xFD14"> <!-- f64x2.splat -->
        <p>Creates a vector with identical lanes.</p>
        <p>Constructs a vector with x replicated to all 2 lanes.</p>
    </div>
    <div id="0xFDEF"> <!-- f64x2.sqrt -->
        <p>Calculates the square root of each lane of a 128-bit vector interpreted as two 64-bit floating point numbers.</p>
    </div>
    <div id="0xFDF1"> <!-- f64x2.sub -->
        <p>Lane-wise subtract of two 128-bit vectors interpreted as two 64-bit floating point numbers.</p>
    </div>
    <div id="0xFD7A"> <!-- f64x2.trunc -->
        <p>Lane-wise rounding to the nearest integral value with the magnitude not larger than the input.</p>
    </div>
    
    <div id="0xFD60"><!-- i8x16.abs -->
        <p>Lane-wise wrapping absolute value.</p>
    </div>
    <div id="0xFD6E"><!-- i8x16.add + u8x16.add -->
        <p>Adds two 128-bit vectors as if they were two packed sixteen 8-bit integers.</p>
    </div>
    <div id="0xFD6F"><!-- i8x16_add_sat [s] -->
        <p>Adds two 128-bit vectors as if they were two packed sixteen 8-bit signed integers, saturating on overflow to i8::MAX.</p>
    </div>
    <div id="0xFD63"><!-- i8x16.all_true + u8x16.all_true -->
        <p>Returns true if all lanes are non-zero, false otherwise.</p>
    </div>
    <div id="0xFD64"><!-- i8x16.bitmask + u8x16.bitmask -->
        <p>Extracts the high bit for each lane in a and produce a scalar mask with all bits concatenated.</p>
    </div>
    <div id="0xFD23"><!-- i8x16.eq + u8x16.eq -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit integers.</p>
        <p>Returns a new vector where each lane is all ones if the corresponding input elements were equal, or all zeros otherwise.</p>
    </div>
    <div id="0xFD15"><!-- i8x16.extract_lane [_s] [i8x16.extract_lane_s + ]-->
        <p>Rust: <code>fn i8x16_extract_lane&lt;const N:&nbsp;usize&gt;(a: v128) -&gt; i8</code></p>
        <p>Extracts a lane from a 128-bit vector interpreted as 16 packed i8 numbers.</p>
        <p>Extracts the scalar value of lane specified in the immediate mode operand <code>N</code> from <code>a</code>. If <code>N</code> is out of bounds then it is a compile time error.</p>
    </div>
    <div id="0xFD2B"><!-- i8x16.ge [_s] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit signed integers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is greater than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD27"><!-- i8x16.gt [_s] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit signed integers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is greater than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD29"><!-- i8x16.le [_s] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit signed integers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is less than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD25"><!-- i8x16_lt [_s] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit signed integers.</p>
        <p>Returns a new vector where each lane is all ones if the lane-wise left element is less than the right element, or all zeros otherwise.</p>
    </div>
    <div id="0xFD78"><!-- i8x16_max [_s] -->
        <p>Compares lane-wise signed integers, and returns the maximum of each pair.</p>
    </div>
    <div id="0xFD76"><!-- i8x16_min [_s] -->
        <p>Compares lane-wise signed integers, and returns the minimum of each pair.</p>
    </div>
    <div id="0xFD65"><!-- i8x16.narrow_i16x8 [_s] -->
        <p>Converts two input vectors into a smaller lane vector by narrowing each lane.</p>
        <p>Signed saturation to 0x7f or 0x80 is used and the input lanes are always interpreted as signed integers.</p>
    </div>
    <div id="0xFD24"><!-- i8x16.ne + u8x16.ne-->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit integers.</p>
        <p>Returns a new vector where each lane is all ones if the corresponding input elements were not equal, or all zeros otherwise.</p>
    </div>
    <div id="0xFD61"><!-- i8x16_neg -->
        <p>Negates a 128-bit vectors interpreted as sixteen 8-bit signed integers</p>
    </div>
    <div id="0xFD62"><!-- i8x16.popcnt + u8x16.popcnt -->
        <p>Count the number of bits set to one within each lane.</p>
    </div>
    <div id="0xFD17"><!-- i8x16.replace_lane + u8x16.replace_lane -->
        <p>Replaces a lane from a 128-bit vector interpreted as 16 packed i8 numbers.</p>
        <p>Replaces the scalar value of lane specified in the immediate mode operand N from 'a'. If N is out of bounds then it is a compile time error.</p>
    </div>
    <div id="0xFD6B"><!-- i8x16.shl + u8x16.shl -->
        <p>Shifts each lane to the left by the specified number of bits.</p>
        <p>Only the low bits of the shift amount are used if the shift amount is greater than the lane width.</p>
    </div>
    <div id="0xFD6C"><!-- i8x16.shr [_s] -->
        <p>Shifts each lane to the right by the specified number of bits, sign extending.</p>
        <p>Only the low bits of the shift amount are used if the shift amount is greater than the lane width.</p>
    </div>
    <div id="0xFD0D"><!-- i8x16.shuffle + u8x16.shuffle -->
        <p>Returns a new vector with lanes selected from the lanes of the two input vectors $a and $b specified in the 16 immediate operands.</p>
    </div>
    <div id="0xFD0F"><!-- i8x16.splat + u8x16.splat -->
        <p>Creates a vector with identical lanes.</p>
        <p>Constructs a vector with x replicated to all 16 lanes.</p>
    </div>
    <div id="0xFD71"><!-- i8x16.sub + u8x16.sub -->
        <p>Subtracts two 128-bit vectors as if they were two packed sixteen 8-bit integers.</p>
    </div>
    <div id="0xFD72"><!-- i8x16_sub_sat [_s] -->
        <p>Subtracts two 128-bit vectors as if they were two packed sixteen 8-bit signed integers, saturating on overflow to i8::MIN.</p>
    </div>
    <div id="0xFD0E"><!-- i8x16.swizzle + u8x16.swizzle -->
        <p>Returns a new vector with lanes selected from the lanes of the first input vector a specified in the second input vector s.</p>
        <p>The indices i in range [0, 15] select the i-th element of 'a'. For indices outside of the range the resulting lane is 0.</p>
    </div>
    
    <div id="0xFD80"><!-- i16x8.abs -->
        <p>Lane-wise wrapping absolute value.</p>
    </div>
    <div id="0xFD8E"><!-- i16x8.add + u16x8.add -->
        <p>Adds two 128-bit vectors as if they were two packed eight 16-bit integers.</p>
    </div>
    <div id="0xFD8F"><!-- i16x8.add_sat [_s] -->
        <p>Adds two 128-bit vectors as if they were two packed eight 16-bit signed integers, saturating on overflow to i16::MAX.</p>
    </div>
    <div id="0xFD83"><!-- i16x8.all_true + u16x8.all_true -->
        <p>Returns true if all lanes are non-zero, false otherwise.</p>
    </div>
    <div id="0xFD84"><!-- i16x8.bitmask + u16x8.bitmask -->
        <p>Extracts the high bit for each lane in a and produce a scalar mask with all bits concatenated.</p>
    </div>
    <div id="0xFD2D"><!-- i16x8.eq + u16x8.eq -->
        <p>Compares two 128-bit vectors as if they were two vectors of 8 sixteen-bit integers.</p>
    </div>
    <div id="0xFD7C"><!-- i16x8.extadd_pairwise_i8x16 [_s] -->
        <p>Integer extended pairwise addition producing extended results (twice wider results than the inputs).</p>
    </div>
    <div id="0xFD88"><!-- i16x8.extend_high_i8x16 [_s] -->
        <p>Converts high half of the smaller lane vector to a larger lane vector, sign extended.</p>
    </div>
    <div id="0xFD87"><!-- i16x8.extend_low_i8x16 [i16x8.extend_low_i8x16_s] -->
        <p>Converts low half of the smaller lane vector to a larger lane vector, sign extended.</p>
    </div>
    <div id="0xFD9D"><!-- i16x8.extmul_high_i8x16 [i16x8.extmul_high_i8x16_s] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFD9C"><!-- i16x8.extmul_low_i8x16 [i16x8.extmul_low_i8x16_s] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFD18"><!-- i16x8.extract_lane [_s] -->
        <p>Extracts a lane from a 128-bit vector interpreted as 8 packed i16 numbers.</p>
    </div>
    <div id="0xFD35"><!-- i16x8.ge [_s] [8 sixteen-bit integers] -->
        <p>Compares two 128-bit vectors as if they were two vectors of eight 16-bit signed integers.</p>
    </div>
    <div id="0xFD31"><!-- i16x8.gt -->
        <p>Compares two 128-bit vectors as if they were two vectors of eight 16-bit signed integers.</p>
    </div>
    <div id="0xFD33"><!-- i16x8.le -->
        <p>Compares two 128-bit vectors as if they were two vectors of eight 16-bit signed integers.</p>
    </div>
    
    <!-- todo: i16x8.load_extend_i8x8 -->
    <!-- todo: i16x8.load_extend_u8x8 -->
    
    <div id="0xFD2F"><!-- i16x8.lt [_s] -->
        <p>Compares two 128-bit vectors as if they were two vectors of eight 16-bit signed integers.</p>
    </div>
    <div id="0xFD98"><!-- i16x8.max -->
        <p>Compares lane-wise signed integers, and returns the maximum of each pair.</p>
    </div>
    <div id="0xFD96"><!-- i16x8.min -->
        <p>Compares lane-wise signed integers, and returns the minimum of each pair.</p>
    </div>
    <div id="0xFD95"><!-- i16x8.mul + u16x8.mul [had "signed integers" for both] -->
        <p>Multiplies two 128-bit vectors as if they were two packed eight 16-bit integers.</p>
    </div>
    <div id="0xFD85"><!-- i16x8.narrow_i32x4 -->
        <p>Converts two input vectors into a smaller lane vector by narrowing each lane.</p>
    </div>
    <div id="0xFD2E"><!-- i16x8.ne + u16x8.ne -->
        <p>Compares two 128-bit vectors as if they were two vectors of 8 sixteen-bit integers.</p>
    </div>
    <div id="0xFD81"><!-- i16x8.neg -->
        <p>Negates a 128-bit vectors interpreted as eight 16-bit signed integers</p>
    </div>
    <div id="0xFD82">
        <p>Rust: <code>fn i16x8_q15mulr_sat(a: v128, b: v128) -> v128</code></p>
        <p>Lane-wise saturating rounding multiplication in Q15 format.</p>
    </div>
    <div id="0xFD1A"><!-- i16x8.replace_lane + u16x8.replace_lane -->
        <p>Replaces a lane from a 128-bit vector interpreted as 8 packed i16 numbers.</p>
    </div>
    <div id="0xFD8B"><!-- i16x8.shl + u16x8.shl -->
        <p>Shifts each lane to the left by the specified number of bits.</p>
    </div>
    <div id="0xFD8C"><!-- i16x8.shr -->
        <p>Shifts each lane to the right by the specified number of bits, sign extending.</p>
    </div>
    <div id="0xXXXX_i16x8.shuffle"><!-- i16x8.shuffle [not an opcode]; https://doc.rust-lang.org/stable/core/arch/wasm/fn.u16x8_shuffle.html -->
        <p>Same as i8x16_shuffle, except operates as if the inputs were eight 16-bit integers, only taking 8 indices to shuffle.</p>
        <p>Indices in the range [0, 7] select from a while [8, 15] select from b. Note that this will generate the i8x16.shuffle instruction, since there is no native i16x8.shuffle instruction (there is no need for one since i8x16.shuffle suffices).</p>
    </div>
    <div id="0xFD10"><!-- i16x8.splat + u16x8.splat -->
        <p>Creates a vector with identical lanes.</p>
    </div>
    <div id="0xFD91"><!-- i16x8.sub + u16x8.sub -->
        <p>Subtracts two 128-bit vectors as if they were two packed eight 16-bit integers.</p>
    </div>
    <div id="0xFD92"><!-- i16x8.sub_sat -->
        <p>Subtracts two 128-bit vectors as if they were two packed eight 16-bit signed integers, saturating on overflow to i16::MIN.</p>
    </div>
    
    <div id="0xFDA0"><!-- i32x4.abs -->
        <p>Lane-wise wrapping absolute value.</p>
    </div>
    <div id="0xFDAE"><!-- i32x4.add + u32x4.add -->
        <p>Adds two 128-bit vectors as if they were two packed four 32-bit integers.</p>
    </div>
    <div id="0xFDA3"><!-- i32x4.all_true + u32x4.all_true -->
        <p>Returns true if all lanes are non-zero, false otherwise.</p>
    </div>
    <div id="0xFDA4"><!-- i32x4.bitmask + u32x4.bitmask -->
        <p>Extracts the high bit for each lane in a and produce a scalar mask with all bits concatenated.</p>
    </div>
    <div id="0xFDBA"><!-- i32x4.dot_i16x8 [_s] -->
        <p>Lane-wise multiply signed 16-bit integers in the two input vectors and add adjacent pairs of the full 32-bit results.</p>
    </div>
    <div id="0xFD37"><!-- i32x4.eq + u32x4.eq -->
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit integers.</p>
    </div>
    <div id="0xFD7E"><!-- i32x4.extadd_pairwise_i16x8 [_s] -->
        <p>Integer extended pairwise addition producing extended results (twice wider results than the inputs).</p>
    </div>
    <div id="0xFDA8"><!-- i32x4.extend_high_i16x8 -->
        <p>Converts high half of the smaller lane vector to a larger lane vector, sign extended.</p>
    </div>
    <div id="0xFDA7"><!-- i32x4.extend_low_i16x8 -->
        <p>Converts low half of the smaller lane vector to a larger lane vector, sign extended.</p>
    </div>
    <div id="0xFDBD"><!-- i32x4.extmul_high_i16x8 -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFDBC"><!-- i32x4.extmul_low_i16x8 -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFD1B"><!-- i32x4.extract_lane + u32x4.extract_lane -->
        <p>Extracts a lane from a 128-bit vector interpreted as 4 packed i32 numbers.</p>
    </div>
    <div id="0xFD3F"><!-- i32x4.ge [_s] [four 32-bit -- 4 thirty-two-bit]-->
        <p>Compares two 128-bit vectors as if they were two vectors of four 32-bit signed integers.</p>
    </div>
    <div id="0xFD3B"><!-- i32x4.gt -->
        <p>Compares two 128-bit vectors as if they were two vectors of four 32-bit signed integers.</p>
    </div>
    <div id="0xFD3D"><!-- i32x4.le -->
        <p>Compares two 128-bit vectors as if they were two vectors of four 32-bit signed integers.</p>
    </div>
    <!-- i32x4.load_extend_i16x4 <p>Load four 16-bit integers and sign extend each one to a 32-bit lane</p> -->
    <!-- i32x4.load_extend_u16x4 <p>Load four 16-bit integers and zero extend each one to a 32-bit lane</p> -->
        
    <div id="0xFD39"><!-- i32x4.lt -->
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit signed integers.</p>
    </div>
    <div id="0xFDB8"><!-- i32x4.max [_s] -->
        <p>Compares lane-wise signed integers, and returns the maximum of each pair.</p>
    </div>
    <div id="0xFDB6"><!-- i32x4.min [_s] -->
        <p>Compares lane-wise signed integers, and returns the minimum of each pair.</p>
    </div>
    <div id="0xFDB5"><!-- i32x4.mul + u32x4.mul [signed or unsigned?] -->
        <p>Multiplies two 128-bit vectors as if they were two packed four 32-bit signed integers.</p>
    </div>
    <div id="0xFD38"><!-- i32x4.ne + u32x4.ne -->
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit integers.</p>
    </div>
    <div id="0xFDA1"><!-- i32x4.neg -->
        <p>Negates a 128-bit vectors interpreted as four 32-bit signed integers</p>
    </div>
    <div id="0xFD1C"><!-- i32x4.replace_lane + u32x4.replace_lane -->
        <p>Replaces a lane from a 128-bit vector interpreted as 4 packed i32 numbers.</p>
    </div>
    <div id="0xFDAB"><!-- i32x4.shl + u32x4.shl -->
        <p>Shifts each lane to the left by the specified number of bits.</p>
    </div>
    <div id="0xFDAC"><!-- i32x4.shr [_s] -->
        <p>Shifts each lane to the right by the specified number of bits, sign extending.</p>
    </div>
    <!-- i32x4.shuffle <p>Same as i8x16_shuffle, except operates as if the inputs were four 32-bit integers, only taking 4 indices to shuffle.</p> [not an op code] -->
    
    <div id="0xFD11"><!-- i32x4.splat + u32x4.splat -->
        <p>Creates a vector with identical lanes.</p>
    </div>
    <div id="0xFDB1"><!-- i32x4.sub + u32x4.sub -->
        <p>Subtracts two 128-bit vectors as if they were two packed four 32-bit integers.</p>
    </div>
    <div id="0xFDF8"><!-- i32x4.trunc_sat_f32x4 [_s] -->
        <p>Converts a 128-bit vector interpreted as four 32-bit floating point numbers into a 128-bit vector of four 32-bit signed integers.</p>
    </div>
    <div id="0xFDFC"><!-- i32x4.trunc_sat_f64x2_zero [i32x4.trunc_sat_f64x2_s_zero] -->
        <p>Saturating conversion of the two double-precision floating point lanes to two lower integer lanes using the IEEE convertToIntegerTowardZero function.</p>
    </div>
    <div id="0xFDC0"><!-- i64x2.abs -->
        <p>Lane-wise wrapping absolute value.</p>
    </div>
    <div id="0xFDCE"><!-- i64x2.add + u64x2.add -->
        <p>Adds two 128-bit vectors as if they were two packed two 64-bit integers.</p>
    </div>
    <div id="0xFDC3"><!-- i64x2.all_true + u64x2.all_true-->
        <p>Returns true if all lanes are non-zero, false otherwise.</p>
    </div>
    <div id="0xFDC4"><!-- i64x2.bitmask + u64x2.bitmask -->
        <p>Extracts the high bit for each lane in 'a' and produce a scalar mask with all bits concatenated.</p>
    </div>
    <div id="0xFDD6"><!-- i64x2.eq + u64x2.eq -->
        <p>Compares two 128-bit vectors as if they were two vectors of two 64-bit integers.</p>
    </div>
    <div id="0xFDC8"><!-- i64x2.extend_high_i32x4 [_s] -->
        <p>Converts high half of the smaller lane vector to a larger lane vector, sign extended.</p>
    </div>
    <div id="0xFDC7"><!-- i64x2.extend_low_i32x4 [_s] -->
        <p>Converts low half of the smaller lane vector to a larger lane vector, sign extended.</p>
    </div>
    <div id="0xFDDD"><!-- i64x2.extmul_high_i32x4 [_s] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFDDC"><!-- i64x2.extmul_low_i32x4 [_s] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFD1D"><!-- i64x2.extract_lane + u64x2.extract_lane -->
        <p>Extracts a lane from a 128-bit vector interpreted as 2 packed i64 numbers.</p>
    </div>
    <div id="0xFDDB"><!-- i64x2.ge [_s] [2 sixty-four-bit] -->
        <p>Compares two 128-bit vectors as if they were two vectors of two 64-bit signed integers.</p>
    </div>
    <div id="0xFDD9"><!-- i64x2.gt -->
        <p>Compares two 128-bit vectors as if they were two vectors of two 64-bit signed integers.</p>
    </div>
    <div id="0xFDDA"><!-- i64x2.le -->
        <p>Compares two 128-bit vectors as if they were two vectors of two 64-bit signed integers.</p>
    </div>
    <!-- i64x2.load_extend_i32x2 <p>Load two 32-bit integers and sign extend each one to a 64-bit lane</p> -->
    <!-- i64x2.load_extend_u32x2 <p>Load two 32-bit integers and zero extend each one to a 64-bit lane</p> -->
    
    <div id="0xFDD8"><!-- i64x2.lt [_s] -->
        <p>Compares two 128-bit vectors as if they were two vectors of two 64-bit signed integers.</p>
    </div>
    <div id="0xFDD5"><!-- i64x2.mul + u64x2.mul -->
        <p>Multiplies two 128-bit vectors as if they were two packed two 64-bit integers.</p>
    </div>
    <div id="0xFDD7"><!-- i64x2.ne + u64x2.ne -->
        <p>Compares two 128-bit vectors as if they were two vectors of two 64-bit integers.</p>
    </div>
    <div id="0xFDC1"><!-- i64x2.neg -->
        <p>Negates a 128-bit vectors interpreted as two 64-bit signed integers</p>
    </div>
    <div id="0xFD1E"><!-- i64x2.replace_lane + u64x2.replace_lane -->
        <p>Replaces a lane from a 128-bit vector interpreted as 2 packed i64 numbers.</p>
    </div>
    <div id="0xFDCB"><!-- i64x2.shl + u64x2.shl -->
        <p>Shifts each lane to the left by the specified number of bits.</p>
    </div>
    <div id="0xFDCC"><!-- i64x2.shr [_s] -->
        <p>Shifts each lane to the right by the specified number of bits, sign extending.</p>
    </div>
    
    <!-- i64x2.shuffle <p>Same as i8x16_shuffle, except operates as if the inputs were two 64-bit integers, only taking 2 indices to shuffle.</p> -->
        
    <div id="0xFD12"><!-- i64x2.splat + u64x2.splat -->
        <p>Creates a vector with identical lanes.</p>
    </div>
    <div id="0xFDD1"><!-- i64x2.sub + u64x2.sub -->
        <p>Subtracts two 128-bit vectors as if they were two packed two 64-bit integers.</p>
    </div>
    <div id="0xFD70"><!-- u8x16.add_sat [i8x16.add_sat_u] -->
        <p>Adds two 128-bit vectors as if they were two packed sixteen 8-bit unsigned integers, saturating on overflow to u8::MAX.</p>
    </div>
    <div id="0xFD7B"><!-- u8x16.avgr [i8x16.avgr_u] -->
        <p>Lane-wise rounding average.</p>
    </div>
    <div id="0xFD16"><!-- u8x16.extract_lane [i8x16.extract_lane_u] -->
        <p>Extracts a lane from a 128-bit vector interpreted as 16 packed u8 numbers.</p>
    </div>
    <div id="0xFD2C"><!-- u8x16.ge [i8x16.ge_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit unsigned integers.</p>
    </div>
    <div id="0xFD28"><!-- u8x16.gt [i8x16.gt_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit unsigned integers.</p>
    </div>
    <div id="0xFD2A"><!-- u8x16.le [i8x16.le_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit unsigned integers.</p>
    </div>
    <div id="0xFD26"><!-- u8x16.lt [i8x16.lt_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 16 eight-bit unsigned integers.</p>
    </div>
    <div id="0xFD79"><!-- u8x16.max [i8x16.max_u] -->
        <p>Compares lane-wise unsigned integers, and returns the maximum of each pair.</p>
    </div>
    <div id="0xFD77"><!-- u8x16.min [i8x16.min_u] -->
        <p>Compares lane-wise unsigned integers, and returns the minimum of each pair.</p>
    </div>
    <div id="0xFD66"><!-- u8x16.narrow_i16x8 [i8x16.narrow_i16x8_u] -->
        <p>Converts two input vectors into a smaller lane vector by narrowing each lane.</p>
    </div>
    <div id="0xFD6D"><!-- u8x16.shr [i8x16.shr_u] -->
        <p>Shifts each lane to the right by the specified number of bits, shifting in zeros.</p>
    </div>
    <div id="0xFD73"><!-- u8x16.sub_sat [i8x16.sub_sat_u]  -->
        <p>Subtracts two 128-bit vectors as if they were two packed sixteen 8-bit unsigned integers, saturating on overflow to 0.</p>
    </div>
    <div id="0xFD90"><!-- u16x8.add_sat [i16x8.add_sat_u] -->
        <p>Adds two 128-bit vectors as if they were two packed eight 16-bit unsigned integers, saturating on overflow to u16::MAX.</p>
    </div>
    <div id="0xFD9B"><!-- u16x8.avgr [i16x8.avgr_u] -->
        <p>Lane-wise rounding average.</p>
    </div>
    <div id="0xFD7D"><!-- u16x8.extadd_pairwise_u8x16 [i16x8.extadd_pairwise_i8x16_u] -->
        <p>Integer extended pairwise addition producing extended results (twice wider results than the inputs).</p>
    </div>
    <div id="0xFD8A"><!-- u16x8.extend_high_u8x16 [i16x8.extend_high_i8x16_u] -->
        <p>Converts high half of the smaller lane vector to a larger lane vector, zero extended.</p>
    </div>
    <div id="0xFD89"><!-- u16x8.extend_low_u8x16 [i16x8.extend_low_i8x16_u] -->
        <p>Converts low half of the smaller lane vector to a larger lane vector, zero extended.</p>
    </div>
    <div id="0xFD9F"><!-- u16x8.extmul_high_u8x16 [i16x8.extmul_high_i8x16_u] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFD9E"><!-- u16x8.extmul_low_u8x16 [i16x8.extmul_low_i8x16_u] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFD19"><!-- u16x8.extract_lane [i16x8.extract_lane_u] -->
        <p>Extracts a lane from a 128-bit vector interpreted as 8 packed u16 numbers.</p>
    </div>
    <div id="0xFD36"><!-- u16x8.ge [i16x8.ge_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 8 sixteen-bit unsigned integers.</p>
    </div>
    <div id="0xFD32"><!-- u16x8.gt [i16x8.gt_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 8 sixteen-bit unsigned integers.</p>
    </div>
    <div id="0xFD34"><!-- u16x8.le -->
        <p>Compares two 128-bit vectors as if they were two vectors of 8 sixteen-bit unsigned integers.</p>
    </div>
    <!-- u16x8.load_extend_u8x8 <p>Load eight 8-bit integers and zero extend each one to a 16-bit lane</p> -->
        
    <div id="0xFD30"><!-- u16x8.lt [i16x8.lt_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 8 sixteen-bit unsigned integers.</p>
    </div>
    <div id="0xFD99"><!-- u16x8.max [i16x8.max_u] -->
        <p>Compares lane-wise unsigned integers, and returns the maximum of each pair.</p>
    </div>
    <div id="0xFD97"><!-- u16x8.min [i16x8.min_u] -->
        <p>Compares lane-wise unsigned integers, and returns the minimum of each pair.</p>
    </div>
    <div id="0xFD86"><!-- u16x8.narrow_i32x4 [i16x8.narrow_i32x4_u] -->
        <p>Converts two input vectors into a smaller lane vector by narrowing each lane.</p>
    </div>
    <div id="0xFD8D"><!-- u16x8.shr [i16x8.shr_u] -->
        <p>Shifts each lane to the right by the specified number of bits, shifting in zeros.</p>
    </div>
    <!-- u16x8.shuffle <p>Same as i8x16_shuffle, except operates as if the inputs were eight 16-bit integers, only taking 8 indices to shuffle.</p>-->
    <div id="0xFD93"><!-- u16x8.sub_sat [i16x8.sub_sat_u] -->
        <p>Subtracts two 128-bit vectors as if they were two packed eight 16-bit unsigned integers, saturating on overflow to 0.</p>
    </div>
    
    <div id="0xFD7F"><!-- u32x4.extadd_pairwise_u16x8 [i32x4.extadd_pairwise_i16x8_u]-->
        <p>Integer extended pairwise addition producing extended results (twice wider results than the inputs).</p>
    </div>
    <div id="0xFDAA"><!-- u32x4.extend_high_u16x8 [i32x4.extend_high_i16x8_u] -->
        <p>Converts high half of the smaller lane vector to a larger lane vector, zero extended.</p>
    </div>
    <div id="0xFDA9"><!-- u32x4.extend_low_u16x8 [i32x4.extend_low_i16x8_u] -->
        <p>Converts low half of the smaller lane vector to a larger lane vector, zero extended.</p>
    </div>
    <div id="0xFDBF"><!-- u32x4.extmul_high_u16x8 [i32x4.extmul_high_i16x8_u] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFDBE"><!-- u32x4.extmul_low_u16x8 [i32x4.extmul_low_i16x8_u] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFD40"><!-- u32x4.ge [i32x4.ge_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit unsigned integers.</p>
    </div>
    <div id="0xFD3C"><!-- u32x4.gt [i32x4.gt_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit unsigned integers.</p>
    </div>
    <div id="0xFD3E"><!-- u32x4.le [i32x4.le_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit unsigned integers.</p>
    </div>
    
    <!-- u32x4.load_extend_u16x4 <p>Load four 16-bit integers and zero extend each one to a 32-bit lane</p> -->
    
    <div id="0xFD3A"><!-- u32x4.lt [i32x4.lt_u] -->
        <p>Compares two 128-bit vectors as if they were two vectors of 4 thirty-two-bit unsigned integers.</p>
    </div>
    <div id="0xFDB9"><!-- u32x4.max [i32x4.max_u] -->
        <p>Compares lane-wise unsigned integers, and returns the maximum of each pair.</p>
    </div>
    <div id="0xFDB7"><!-- u32x4.min [i32x4.min_u] -->
        <p>Compares lane-wise unsigned integers, and returns the minimum of each pair.</p>
    </div>
    <div id="0xFDAD"><!-- u32x4.shr [i32x4.shr_u] -->
        <p>Shifts each lane to the right by the specified number of bits, shifting in zeros.</p>
    </div>
    
    <!-- u32x4.shuffle <p>Same as i8x16_shuffle, except operates as if the inputs were four 32-bit integers, only taking 4 indices to shuffle.</p> -->
        
    <div id="0xFDF9"><!-- u32x4.trunc_sat_f32x4 [i32x4.trunc_sat_f32x4_u] -->
        <p>Converts a 128-bit vector interpreted as four 32-bit floating point numbers into a 128-bit vector of four 32-bit unsigned integers.</p>
    </div>
    <div id="0xFDFD"><!-- u32x4.trunc_sat_f64x2_zero [i32x4.trunc_sat_f64x2_u_zero] -->
        <p>Saturating conversion of the two double-precision floating point lanes to two lower integer lanes using the IEEE convertToIntegerTowardZero function.</p>
    </div>
    
    <div id="0xFDCA"><!-- u64x2.extend_high_u32x4 [i64x2.extend_high_i32x4_u] -->
        <p>Converts high half of the smaller lane vector to a larger lane vector, zero extended.</p>
    </div>
    <div id="0xFDC9"><!-- u64x2.extend_low_u32x4 [i64x2.extend_low_i32x4_u] -->
        <p>Converts low half of the smaller lane vector to a larger lane vector, zero extended.</p>
    </div>
    <div id="0xFDDF"><!-- u64x2.extmul_high_u32x4 [i64x2.extmul_high_i32x4_u] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    <div id="0xFDDE"><!-- u64x2.extmul_low_u32x4 [i64x2.extmul_low_i32x4_u] -->
        <p>Lane-wise integer extended multiplication producing twice wider result than the inputs.</p>
    </div>
    
    <!-- u64x2.load_extend_u32x2 <p>Load two 32-bit integers and zero extend each one to a 64-bit lane</p> -->
    
    <div id="0xFDCD"><!-- u64x2.shr [i64x2.shr_u] -->
        <p>Shifts each lane to the right by the specified number of bits, shifting in zeros.</p>
    </div>
    
    <!-- u64x2.shuffle <p>Same as i8x16_shuffle, except operates as if the inputs were two 64-bit integers, only taking 2 indices to shuffle. -->
    
    <div id="0xFD4E"><!-- v128.and -->
        <p>Performs a bitwise and of the two input 128-bit vectors, returning the resulting vector.</p>
    </div>
    <div id="0xFD4F"><!-- v128.andnot -->
        <p>Bitwise AND of bits of a and the logical inverse of bits of b.</p>
    </div>
    <div id="0xFD53"><!-- v128.any_true -->
        <p>Returns true if any bit in a is set, or false otherwise.</p>
    </div>
    <div id="0xFD52"><!-- v128.bitselect -->
        <p>Rust: <code>fn v128_bitselect(v1: v128, v2: v128, c: v128) -> v128</code></p>
        <p>Use the bitmask in <code>c</code> to select bits from <code>v1</code> when 1 and <code>v2</code> when 0.</p>	
    </div>
    <div id="0xFD00"><!-- v128.load -->
        <p>Loads a v128 vector from the given heap address.</p>
    </div>
    <div id="0xFD54"><!-- v128.load8_lane -->
        <p>Loads an 8-bit value from m and sets lane L of v to that value.</p>
    </div>
    <div id="0xFD07"><!-- v128.load8_splat -->
        <p>Load a single element and splat to all lanes of a v128 vector.</p>
    </div>
    <div id="0xFD55"><!-- v128.load16_lane -->
        <p>Loads a 16-bit value from m and sets lane L of v to that value.</p>
    </div>
    <div id="0xFD08"><!-- v128.load16_splat -->
        <p>Load a single element and splat to all lanes of a v128 vector.</p>
    </div>
    <div id="0xFD56"><!-- v128.load32_lane -->
        <p>Loads a 32-bit value from m and sets lane L of v to that value.</p>
    </div>
    <div id="0xFD09"><!-- v128.load32_splat -->
        <p>Load a single element and splat to all lanes of a v128 vector.</p>
    </div>
    <div id="0xFD5C"><!-- v128.load32_zero -->
        <p>Load a 32-bit element into the low bits of the vector and sets all other bits to zero.</p>
    </div>
    <div id="0xFD57"><!-- v128.load64_lane -->
        <p>Loads a 64-bit value from m and sets lane L of v to that value.</p>
    </div>
    <div id="0xFD0A"><!-- v128.load64_splat -->
        <p>Load a single element and splat to all lanes of a v128 vector.</p>
    </div>
    <div id="0xFD5D"><!-- v128.load64_zero -->
        <p>Load a 64-bit element into the low bits of the vector and sets all other bits to zero.</p>
    </div>
    <div id="0xFD4D"><!-- v128.not -->
        <p>Flips each bit of the 128-bit input vector.</p>
    </div>
    <div id="0xFD50"><!-- v128.or -->
        <p>Performs a bitwise or of the two input 128-bit vectors, returning the resulting vector.</p>
    </div>
    <div id="0xFD0B"><!-- v128.store -->
        <p>Stores a v128 vector to the given heap address.</p>
    </div>
    <div id="0xFD58"><!-- v128.store8_lane -->
        <p>Stores the 8-bit value from lane L of v into m</p>
    </div>
    <div id="0xFD59"><!-- v128.store16_lane -->
        <p>Stores the 16-bit value from lane L of v into m</p>
    </div>
    <div id="0xFD5A"><!-- v128.store32_lane -->
        <p>Stores the 32-bit value from lane L of v into m</p>
    </div>
    <div id="0xFD5B"><!-- v128.store64_lane -->
        <p>Stores the 64-bit value from lane L of v into m</p>
    </div>
    <div id="0xFD51"><!-- v128.xor -->
        <p>Performs a bitwise xor of the two input 128-bit vectors, returning the resulting vector.</p>
    </div>
    
    {/* <!-- 0xFD Relaxed SIMD instructions, prototype locations --> */}
    <div id="0xFDA2"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDA5"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDA6"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDC5"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDC6"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDAF"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDB0"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDCF"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDD0"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDB2"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDB3"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDD2"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDD3"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDB4"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDE2"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDD4"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    <div id="0xFDEE"><p>Opcode location during prototyping.</p><p><em>Relaxed SIMD proposal</em></p></div>
    
    <div id="0xFD100">
        <p><code>relaxed i8x16.swizzle(a, s)</code> selects lanes from <code>a</code> using indices in <code>s</code>,
    indices in the range <code>[0,15]</code> will select the <code>i</code>-th element of <code>a</code>, the result
    for any out of range indices is implementation-defined (i.e. if the index is <code>[16-255]</code>.</p>
    </div>
    <div id="0xFD101">
        <p><code>relaxed i32x4.trunc_f32x4_s</code> (relaxed version of <code>i32x4.trunc_sat_f32x4_s</code>)</p>
        <p>This instruction has the same behavior as the non-relaxed instruction for lanes that are in the range of an <code>i32</code> (signed or unsigned depending on the instruction). The result of lanes which contain NaN is implementation defined, either 0 or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned. The result of lanes which are out of bounds of <code>INT32</code> or <code>UINT32</code> is implementation defined, it can be either the saturated result or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned.</p>
    </div>
    <div id="0xFD102">
        <p><code>relaxed i32x4.trunc_f32x4_u</code> (relaxed version of <code>i32x4.trunc_sat_f32x4_u</code>)</p>
        <p>This instruction has the same behavior as the non-relaxed instruction for lanes that are in the range of an <code>i32</code> (signed or unsigned depending on the instruction). The result of lanes which contain NaN is implementation defined, either 0 or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned. The result of lanes which are out of bounds of <code>INT32</code> or <code>UINT32</code> is implementation defined, it can be either the saturated result or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned.</p>
    </div>
    <div id="0xFD103">
        <p><code>relaxed i32x4.trunc_f64x2_s_zero</code> (relaxed version of <code>i32x4.trunc_sat_f64x2_s_zero</code>)</p>
        <p>This instruction has the same behavior as the non-relaxed instruction for lanes that are in the range of an <code>i32</code> (signed or unsigned depending on the instruction). The result of lanes which contain NaN is implementation defined, either 0 or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned. The result of lanes which are out of bounds of <code>INT32</code> or <code>UINT32</code> is implementation defined, it can be either the saturated result or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned.</p>
    </div>
    <div id="0xFD104">
        <p><code>relaxed i32x4.trunc_f64x2_u_zero</code> (relaxed version of <code>i32x4.trunc_sat_f64x2_u_zero</code>)</p>
        <p>This instruction has the same behavior as the non-relaxed instruction for lanes that are in the range of an <code>i32</code> (signed or unsigned depending on the instruction). The result of lanes which contain NaN is implementation defined, either 0 or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned. The result of lanes which are out of bounds of <code>INT32</code> or <code>UINT32</code> is implementation defined, it can be either the saturated result or <code>INT32_MAX</code> for signed and <code>UINT32_MAX</code> for unsigned.</p>
    </div>
    <div id="0xFD105">
        <p><span className="name">Relaxed fused multiply-add</span></p>
        <p><code>relaxed f32x4.madd(a, b, c) = a * b + c</code></p>
        <p>where:
        <ul>
        <li>the intermediate a * b is be rounded first, and the final result rounded again (for a total of 2 roundings), or</li>
        <li>the entire expression evaluated with higher precision and then only rounded once (if supported by hardware).</li>
        </ul>
        </p>
    </div>
    <div id="0xFD106">
        <p>Relaxed fused negative multiply-add</p>
        <p><code>relaxed f32x4.nmadd(a, b, c) = -(a * b) + c</code></p>
        <p>where:
        <ul>
        <li>the intermediate a * b is be rounded first, and the final result rounded again (for a total of 2 roundings), or</li>
        <li>the entire expression evaluated with higher precision and then only rounded once (if supported by hardware).</li>
        </ul>
        </p>
    </div>
    <div id="0xFD107">
        <p>Relaxed fused multiply-add</p>
        <p><code>relaxed f64x2.madd(a, b, c) = a * b + c</code></p>
        <p>where:
        <ul>
        <li>the intermediate a * b is be rounded first, and the final result rounded again (for a total of 2 roundings), or</li>
        <li>the entire expression evaluated with higher precision and then only rounded once (if supported by hardware).</li>
        </ul>
        </p>
    </div>
    <div id="0xFD108">
        <p>Relaxed fused negative multiply-add</p>
        <p><code>relaxed f64x2.nmadd(a, b, c) = -(a * b) + c</code></p>
        <p>where:
        <ul>
        <li>the intermediate a * b is be rounded first, and the final result rounded again (for a total of 2 roundings), or</li>
        <li>the entire expression evaluated with higher precision and then only rounded once (if supported by hardware).</li>
        </ul>
        </p>
    </div>
    <div id="0xFD109">
        <p><code>i8x16.laneselect(a: v128, b: v128, m: v128) -> v128</code></p>
        <p>Select lanes from <code>a</code> or <code>b</code> based on masks in <code>m</code>. If each lane-sized mask in <code>m</code> has all bits set or all bits unset, these instructions behave the same as <code>v128.bitselect</code>. Otherwise, the result is implementation defined.</p>
    </div>
    <div id="0xFD10A">
        <p><code>i16x8.laneselect(a: v128, b: v128, m: v128) -> v128</code></p>
        <p>Select lanes from <code>a</code> or <code>b</code> based on masks in <code>m</code>. If each lane-sized mask in <code>m</code> has all bits set or all bits unset, these instructions behave the same as <code>v128.bitselect</code>. Otherwise, the result is implementation defined.</p>
    </div>
    <div id="0xFD10B">
        <p><code>i32x4.laneselect(a: v128, b: v128, m: v128) -> v128</code></p>
        <p>Select lanes from <code>a</code> or <code>b</code> based on masks in <code>m</code>. If each lane-sized mask in <code>m</code> has all bits set or all bits unset, these instructions behave the same as <code>v128.bitselect</code>. Otherwise, the result is implementation defined.</p>
    </div>
    <div id="0xFD10C">
        <p><code>i64x2.laneselect(a: v128, b: v128, m: v128) -> v128</code></p>
        <p>Select lanes from <code>a</code> or <code>b</code> based on masks in <code>m</code>. If each lane-sized mask in <code>m</code> has all bits set or all bits unset, these instructions behave the same as <code>v128.bitselect</code>. Otherwise, the result is implementation defined.</p>
    </div>
    <div id="0xFD10D">
        <p>Relaxed min</p>
        <p><code>f32x4.min(a: v128, b: v128) -> v128</code></p>
        <p>Return the lane-wise minimum of two values. If either values is NaN, or the values are -0.0 and +0.0, the return value is implementation-defined.</p>
    </div>
    <div id="0xFD10E">
        <p>Relaxed max</p>
        <p><code>f32x4.max(a: v128, b: v128) -> v128</code></p>
        <p>Return the lane-wise maximum of two values. If either values is NaN, or the values are -0.0 and +0.0, the return value is implementation-defined.</p>
    </div>
    <div id="0xFD10F">
        <p>Relaxed min</p>
        <p><code>f64x2.min(a: v128, b: v128) -> v128</code></p>
        <p>Return the lane-wise minimum of two values. If either values is NaN, or the values are -0.0 and +0.0, the return value is implementation-defined.</p>
    </div>
    <div id="0xFD110">
        <p>Relaxed max</p>
        <p><code>f64x2.max(a: v128, b: v128) -> v128</code></p>
        <p>Return the lane-wise maximum of two values. If either values is NaN, or the values are -0.0 and +0.0, the return value is implementation-defined.</p>
    </div>
    <div id="0xFD111">
        <p>Relaxed Rounding Q-format Multiplication</p>
        <p><code>i16x8.q15mulr_s(a: v128, b: v128) -> v128</code></p>
        <p>Returns the multiplication of 2 fixed-point numbers in Q15 format. If both inputs are <code>INT16_MIN</code>, the result overflows, and the return value is implementation defined (either <code>INT16_MIN</code> or <code>INT16_MAX</code>).</p>
    </div>
    <div id="0xFD112">
        <p>Relaxed integer dot product</p>
        <p><code>i16x8.dot_i8x16_i7x16_s(a: v128, b: v128) -> v128</code></p>
        <p>Returns the multiplication of 8-bit elements (signed or unsigned) by 7-bit elements (unsigned) with accumulation of adjacent products. The <code>i32x4</code> versions allows for accumulation into another vector.</p>
        <p>When the second operand of the product has the high bit set in a lane, that lane's result is implementation defined.</p>
    </div>
    <div id="0xFD113">
        <p>Relaxed integer dot product</p>
        <p><code>i32x4.dot_i8x16_i7x16_add_s(a: v128, b:v128, c:v128) -> v128</code></p>
        <p>Returns the multiplication of 8-bit elements (signed or unsigned) by 7-bit elements (unsigned) with accumulation of adjacent products. The <code>i32x4</code> versions allows for accumulation into another vector.</p>
        <p>When the second operand of the product has the high bit set in a lane, that lane's result is implementation defined.</p>
    </div>
    <div id="0xFD114">
        <p>Relaxed BFloat16 dot product</p>
        <p><code>i32x4.dot_i8x16_i7x16_add_s(a: v128, b:v128, c:v128) -> v128</code></p>
        <p>BFloat16 is a 16-bit floating-point format that represents the IEEE FP32 numbers truncated to the high 16 bits. This instruction computes a FP32 dot product of 2 BFloat16 with accumulation into another FP32.</p>
    </div>
    
    <!-- Ordered as at https://github.com/WebAssembly/threads/blob/main/proposals/threads/Overview.md#read-modify-write -->
    
    <div id="0xFE12"> <!-- i32.atomic.load8_u --><p>atomically load 1 byte and zero-extend i8 to i32</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE13"><!-- i32.atomic.load16_u --><p>atomically load 2 bytes and zero-extend i16 to i32</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE10"><!-- i32.atomic.load --><p>atomically load 4 bytes as i32</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE14"><!-- i64.atomic.load8_u --><p>atomically load 1 byte and zero-extend i8 to i64</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE15"><!-- i64.atomic.load16_u --><p>atomically load 2 bytes and zero-extend i16 to i64</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE16"><!-- i64.atomic.load32_u --><p>atomically load 4 bytes and zero-extend i32 to i64</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE11"><!-- i64.atomic.load --><p>atomically load 8 bytes as i64</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE19"><!-- i32.atomic.store8 --><p>wrap i32 to i8 and atomically store 1 byte</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE1A"><!-- i32.atomic.store16 --><p>wrap i32 to i16 and atomically store 2 bytes</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE17"><!-- i32.atomic.store --><p>(no conversion) atomically store 4 bytes</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE1B"><!-- i64.atomic.store8 --><p>wrap i64 to i8 and atomically store 1 byte</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE1C"><!-- i64.atomic.store16 --><p>wrap i64 to i16 and atomically store 2 bytes</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE1D"><!-- i64.atomic.store32 --><p>wrap i64 to i32 and atomically store 4 bytes</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    <div id="0xFE18"><!-- i64.atomic.store --><p>(no conversion) atomically store 8 bytes</p><p>Atomic load/store memory accesses behave like their non-atomic counterparts, with the exception that the ordering of accesses is sequentially consistent.</p></div>
    
    <div id="0xFE20"> <!-- i32.atomic.rmw8.add_u -->
        <p>8-bit sign-agnostic addition</p>
        <p>Read: 1 byte, Write: 1 byte</p>
        <p>Returns: zero-extended i8 to i32</p>	
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE21"> <!-- i32.atomic.rmw16.add_u -->
        <p>16-bit sign-agnostic addition</p>
        <p>Read: 2 bytes, Write: 2 bytes</p>
        <p>Returns: zero-extended i16 to i32</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE1E"> <!-- i32.atomic.rmw.add -->
        <p>32-bit sign-agnostic addition</p>
        <p>Read: 4 bytes, Write: 4 bytes</p>
        <p>Returns: as i32</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE22"> <!-- i64.atomic.rmw8.add_u -->
        <p>8-bit sign-agnostic addition</p>
        <p>Returns: zero-extended i8 to i64</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE23"> <!-- i64.atomic.rmw16.add_u -->
        <p>64-bit sign-agnostic addition</p>
        <p>Read: 4 bytes, Write: 4 bytes</p>
        <p>Returns: zero-extended i16 to i64</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE24"> <!-- i64.atomic.rmw32.add_u --> 
        <p>32-bit sign-agnostic addition</p>
        <p>Returns: zero-extended i32 to i64</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE1F"> <!-- i64.atomic.rmw.add --> 
        <p>64-bit sign-agnostic addition</p>
        <p>Read: 8 bytes, Write: 8 bytes</p>
        <p>Returns: as i64</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE27"> <!-- i32.atomic.rmw8.sub_u -->
        <p>8-bit sign-agnostic subtraction</p>
        <p>Read: 1 byte, Write: 1 byte</p>
        <p>Returns: zero-extended i8 to i32</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE28"> <!-- i32.atomic.rmw16.sub_u -->
        <p>16-bit sign-agnostic subtraction</p>
        <p>Read: 2 bytes, Write: 2 bytes</p>
        <p>Returns: zero-extended i16 to i32</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE25"> <!-- i32.atomic.rmw.sub -->
        <p>32-bit sign-agnostic subtraction</p>
        <p>Read: 4 bytes, Write: 4 bytes</p>
        <p>Returns: as i32</p>
        <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
        <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE29"><!-- i64.atomic.rmw8.sub_u --><p>8-bit sign-agnostic subtraction</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE2A"><!-- i64.atomic.rmw16.sub_u --><p>16-bit sign-agnostic subtraction</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE2B"><!-- i64.atomic.rmw32.sub_u --><p>32-bit sign-agnostic subtraction</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: zero-extended i32 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE26"><!-- i64.atomic.rmw.sub --><p>64-bit sign-agnostic subtraction</p><p>Read: 8 bytes, Write: 8 bytes</p><p>Returns: as i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE2E"><!-- i32.atomic.rmw8.and_u --><p>8-bit sign-agnostic bitwise and</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE2F"><!-- i32.atomic.rmw16.and_u --><p>16-bit sign-agnostic bitwise and</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE2C"><!-- i32.atomic.rmw.and --><p>32-bit sign-agnostic bitwise and</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: as i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE30"><!-- i64.atomic.rmw8.and_u --><p>8-bit sign-agnostic bitwise and</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE31"><!-- i64.atomic.rmw16.and_u --><p>16-bit sign-agnostic bitwise and</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE32"><!-- i64.atomic.rmw32.and_u --><p>32-bit sign-agnostic bitwise and</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: zero-extended i32 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE2D"><!-- i64.atomic.rmw.and --><p>64-bit sign-agnostic bitwise and</p><p>Read: 8 bytes, Write: 8 bytes</p><p>Returns: as i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE35"><!-- i32.atomic.rmw8.or_u --><p>8-bit sign-agnostic bitwise inclusive or</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE36"><!-- i32.atomic.rmw16.or_u --><p>16-bit sign-agnostic bitwise inclusive or</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE33"><!-- i32.atomic.rmw.or --><p>32-bit sign-agnostic bitwise inclusive or</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: as i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE37"><!-- i64.atomic.rmw8.or_u --><p>8-bit sign-agnostic bitwise inclusive or</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE38"><!-- i64.atomic.rmw16.or_u --><p>16-bit sign-agnostic bitwise inclusive or</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE39"><!-- i64.atomic.rmw32.or_u --><p>32-bit sign-agnostic bitwise inclusive or</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: zero-extended i32 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE34"><!-- i64.atomic.rmw.or --><p>64-bit sign-agnostic bitwise inclusive or</p><p>Read: 8 bytes, Write: 8 bytes</p><p>Returns: as i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE3C"><!-- i32.atomic.rmw8.xor_u --><p>8-bit sign-agnostic bitwise exclusive or</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE3D"><!-- i32.atomic.rmw16.xor_u --><p>16-bit sign-agnostic bitwise exclusive or</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE3A"><!-- i32.atomic.rmw.xor --><p>32-bit sign-agnostic bitwise exclusive or</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: as i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE3E"><!-- i64.atomic.rmw8.xor_u --><p>8-bit sign-agnostic bitwise exclusive or</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE3F"><!-- i64.atomic.rmw16.xor_u --><p>16-bit sign-agnostic bitwise exclusive or</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE40"><!-- i64.atomic.rmw32.xor_u --><p>32-bit sign-agnostic bitwise exclusive or</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: zero-extended i32 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE3B"><!-- i64.atomic.rmw.xor --><p>64-bit sign-agnostic bitwise exclusive or</p><p>Read: 8 bytes, Write: 8 bytes</p><p>Returns: as i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE43"><!-- i32.atomic.rmw8.xchg_u --><p>nop</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE44"><!-- i32.atomic.rmw16.xchg_u --><p>nop</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE41"><!-- i32.atomic.rmw.xchg --><p>nop</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: as i32</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE45"><!-- i64.atomic.rmw8.xchg_u --><p>nop</p><p>Read: 1 byte, Write: 1 byte</p><p>Returns: zero-extended i8 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE46"><!-- i64.atomic.rmw16.xchg_u --><p>nop</p><p>Read: 2 bytes, Write: 2 bytes</p><p>Returns: zero-extended i16 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE47"><!-- i64.atomic.rmw32.xchg_u --><p>nop</p><p>Read: 4 bytes, Write: 4 bytes</p><p>Returns: zero-extended i32 to i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
    <div id="0xFE42"><!-- i64.atomic.rmw.xchg --><p>nop</p><p>Read: 8 bytes, Write: 8 bytes</p><p>Returns: as i64</p>
    <p>Atomic read-modify-write (RMW) operators atomically read a value from an address, modify the value, and store the resulting value to the same address. All RMW operators return the value read from memory before the modify operation was performed.</p>
    <p>The RMW operators have two operands, an address and a value used in the modify operation.</p>
    </div>
   
    
    
    
    
    </div>`;
export default ExtraInfo;
