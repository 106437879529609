export function getLineType(st: string) {
  if (st === "Fail") {
    return "fail";
  } else if (st === "Done") {
    return "success";
  } else if (st === "Stale") {
    return "stale";
  } else if (st === "DryRunFailed") {
    return "dryrunfail";
  } else {
    return "pending";
  }
}
