// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";
import { Container, ListGroup, Form } from "react-bootstrap";
import { useAppSelector } from "../app/hooks";
import "./style.scss";
import { ModalCommon, ModalCommonProps, ModalStatus } from "./base";
import {
  Task,
  Image,
  VerifyBatchProofParams,
  ProofSubmitMode,
} from "zkwasm-service-helper";

import { selectConfig } from "../data/statusSlice";

import { selectZkWasmServiceHelper } from "../data/endpoint";
import { Inputs, capitalizeFirstLetter } from "../utils/inputs";
import Dropdown from "../components/Dropdown";
import { TextInput } from "../components/Inputs";
import {
  useGetImageByHashQuery,
  useGetRound1InfosQuery,
} from "../data/apiSlice";

import { isError, EthersError } from "ethers";
import { verify_auto_submit_proof, verify_task } from "../utils/proof";
import { compareBytes } from "../utils/amount";
export interface ProofInfoProps {
  task: Task;
  selectedChainId?: number;
}

export function VerifyProofModal(info: ProofInfoProps) {
  let imageHelper = useAppSelector(selectZkWasmServiceHelper);
  const [message, setMessage] = React.useState("");
  const [disableButton, setDisableButton] = React.useState(false);
  const appConfig = useAppSelector(selectConfig);

  const [selectedChainId, setSelectedChainId] = React.useState<number | null>(
    null
  ); // proof or verify
  const [selectedVerifierAddress, setSelectedVerifierAddress] = React.useState<
    string | null
  >("");
  let image = useGetImageByHashQuery(info.task.md5!);
  const [showChainDropdown, setShowChainDropdown] = React.useState(false);

  const [status, setStatus] = React.useState<ModalStatus>(
    ModalStatus.PreConfirm
  );

  let task = info.task;

  const autoSubmitInfo = useGetRound1InfosQuery(
    {
      task_id: task._id["$oid"],
      chain_id: selectedChainId ? selectedChainId : undefined,
    },
    {
      skip: selectedChainId === null,
    }
  );

  useEffect(() => {
    // Refetch the auto submit info when the chain id changes as batch data is different per chain
    if (selectedChainId) {
      autoSubmitInfo.refetch();
    }
  }, [selectedChainId]);

  const isUsingLatest = compareBytes(
    task.task_verification_data.static_file_checksum,
    appConfig.latest_server_checksum
  );

  // CHeck if the static file checksum is all 0s
  const isLegacyTask = task.task_verification_data.static_file_checksum.every(
    (x) => x === 0
  );

  async function testverify() {
    if (!selectedChainId) {
      setMessage("Please select a chain");
      return;
    }
    let image = (await getImageInfo(task.md5!)) as Image;

    if (!selectedVerifierAddress) {
      setMessage("Please select a verifier address");
      return;
    }

    // Old tasks without the proof submit mode metadata will default to manual
    let proof_submit_mode = task.proof_submit_mode;

    try {
      // if the task proof submit mode is auto then we need to verify through the prooftracker contract
      switch (proof_submit_mode) {
        case ProofSubmitMode.Auto: {
          const round_1_output = autoSubmitInfo.data?.data[0];

          if (!round_1_output) {
            setMessage("No round 1 output found");
            return;
          }

          const round_1_target_instances = round_1_output.target_instances.map(
            (x) => {
              return new Uint8Array(x);
            }
          );
          const round_1_shadow_instances = new Uint8Array(
            round_1_output.shadow_instances!
          );

          // Find the index of this proof in the round 1 output by comparing task_ids

          const index = round_1_output.task_ids.findIndex(
            (id) => id === task._id["$oid"]
          );

          let proof_info: VerifyBatchProofParams = {
            membership_proof_index: [BigInt(index)],
            verify_instance: task.shadow_instances,
            sibling_instances: round_1_target_instances,
            round_1_shadow_instance: round_1_shadow_instances,
            target_instances: [task.instances],
          };

          await verify_auto_submit_proof({
            proof_info,
            aggregator_verifier_address: selectedVerifierAddress,
            selectedChainId,
            setMessageCallback: setMessage,
            setDisableButtonCallback: setDisableButton,
            setStatusCallback: setStatus,
          });

          break;
        }

        case ProofSubmitMode.Manual:
        default: {
          await verify_task({
            task,
            image,
            aggregator_verifier_address: selectedVerifierAddress,
            selectedChainId,
            setMessageCallback: setMessage,
            setDisableButtonCallback: setDisableButton,
            setStatusCallback: setStatus,
          });
          break;
        }
      }
    } catch (e) {
      console.error(e);

      // Ethersjs provides a way to check for errors
      // We can handle errors separately based on type
      if (isError(e, "ACTION_REJECTED")) {
        setMessage("User rejected action");
      }
      if (isError(e, "NETWORK_ERROR")) {
        setMessage("Network error sending transaction: " + e.shortMessage);
      }
      if (isError(e, "INSUFFICIENT_FUNDS")) {
        setMessage("Insufficient funds for transaction");
      }
      // Transaction reverted
      if (isError(e, "CALL_EXCEPTION")) {
        setMessage("Transaction reverted: " + e.shortMessage);
      } else {
        let error = e as EthersError;
        console.log("error info", error.info?.name);
        setMessage(
          "Transaction failed with unexpected error. Error code: " +
            error.code +
            "Error message: " +
            error.shortMessage
        );
      }
      setDisableButton(false);
      return;
    }
    setDisableButton(false);
  }

  async function getImageInfo(md5: string) {
    return await imageHelper.queryImage(md5);
  }

  let verifyAutoSubmitOptions = (
    <>
      <Container className="position-relative " style={{ minHeight: "100px" }}>
        <Form.Label variant="dark">
          Auto Submit Verification Chain:{" "}
          <Form.Text className="text-muted ms-2">
            Select the chain to verify the proof on.
          </Form.Text>
        </Form.Label>
        <TextInput
          placeholder="Select a chain for Verification"
          autoComplete="off"
          value={capitalizeFirstLetter(
            selectedChainId
              ? appConfig.chain_info_list.find(
                  (d: any) => d.chain_id == selectedChainId
                )!.chain_name
              : ""
          )}
          id="verification-chain"
          name="chain"
          type="text"
          multiple={false}
          readOnly
          // onChange={(e) => selectChain(e.target.value)}
          onClick={() => setShowChainDropdown(true)}
        ></TextInput>
        {showChainDropdown && image.isSuccess ? (
          <Dropdown handleOutsideClick={() => setShowChainDropdown(false)}>
            {task.task_verification_data.verifier_contracts.map((x) => {
              const chainName = appConfig.chain_info_list.find(
                (d) => d.chain_id == x.chain_id
              )?.chain_name;
              if (!chainName) {
                return;
              }
              return (
                <div
                  className="dropdown-option text-capitalize"
                  key={x.batch_verifier}
                  onClick={() => {
                    setSelectedChainId(x.chain_id);
                    setShowChainDropdown(false);
                    setDisableButton(false);
                    setSelectedVerifierAddress(x.batch_verifier);
                  }}
                >
                  <span className="text-capitalize">
                    {chainName} - {x.batch_verifier}
                  </span>
                </div>
              );
            })}
          </Dropdown>
        ) : null}
        {!isUsingLatest && (
          <>
            {/* todo: checksum for auto submit files need to be handled */}
            {/* <Form.Text>
              * This proof was generated using older versions of zkwasm.
              Verification will use contracts generated from the assosciated
              version.
            </Form.Text> */}
          </>
        )}
      </Container>
    </>
  );

  let verifyOptions = (
    <>
      <Container className="position-relative " style={{ minHeight: "100px" }}>
        <Form.Label variant="dark">
          Verification Chain:{" "}
          <Form.Text className="text-muted ms-2">
            Select the chain to verify the proof on.
          </Form.Text>
        </Form.Label>
        <TextInput
          placeholder="Select a chain for Verification"
          autoComplete="off"
          value={capitalizeFirstLetter(
            selectedChainId
              ? appConfig.chain_info_list.find(
                  (d: any) => d.chain_id == selectedChainId
                )!.chain_name
              : ""
          )}
          id="verification-chain"
          name="chain"
          type="text"
          multiple={false}
          readOnly
          // onChange={(e) => selectChain(e.target.value)}
          onClick={() => setShowChainDropdown(true)}
        ></TextInput>
        {showChainDropdown && image.isSuccess ? (
          <Dropdown handleOutsideClick={() => setShowChainDropdown(false)}>
            {isLegacyTask
              ? // if the task is legacy, then we need to use the old chain info list and the old deployment list
                // which should be using default of 0 checksum
                appConfig.deployments.map((item, index) => {
                  let chainInfo = appConfig.chain_info_list.find(
                    (x) =>
                      x.chain_id === item.chain_id &&
                      item.circuit_size === image.data?.circuit_size &&
                      compareBytes(
                        item.static_file_checksum,
                        task.task_verification_data.static_file_checksum
                      )
                  );

                  if (chainInfo === undefined) return;

                  return (
                    <div
                      className="dropdown-option text-capitalize"
                      key={chainInfo!.chain_name + index}
                      onClick={() => {
                        setSelectedChainId(chainInfo!.chain_id);
                        setShowChainDropdown(false);
                        setDisableButton(false);
                        setSelectedVerifierAddress(item.aggregator_verifier);
                      }}
                    >
                      <span className="text-capitalize">
                        {chainInfo.chain_name} - {item.aggregator_verifier}
                      </span>
                    </div>
                  );
                })
              : task.task_verification_data.verifier_contracts.map((x) => {
                  const chainName = appConfig.chain_info_list.find(
                    (d) => d.chain_id == x.chain_id
                  )?.chain_name;
                  if (!chainName) {
                    return;
                  }
                  return (
                    <div
                      className="dropdown-option text-capitalize"
                      key={x.aggregator_verifier}
                      onClick={() => {
                        setSelectedChainId(x.chain_id);
                        setShowChainDropdown(false);
                        setDisableButton(false);
                        setSelectedVerifierAddress(x.aggregator_verifier);
                      }}
                    >
                      <span className="text-capitalize">
                        {chainName} - {x.aggregator_verifier}
                      </span>
                    </div>
                  );
                })}
          </Dropdown>
        ) : null}
        {!isUsingLatest && (
          <>
            <Form.Text>
              * This proof was generated using older versions of zkwasm.
              Verification will use contracts generated from the assosciated
              version.
            </Form.Text>
          </>
        )}
      </Container>
    </>
  );

  let props: ModalCommonProps = {
    btnLabel: <div className="verify-proof-btn">Verify</div>,
    title: "Proof Information",
    childrenClass: "",
    modalClass: "",
    handleConfirm: function (): void {
      if (selectedChainId != null) {
        testverify();
      }
    },
    handleClose: () => {
      setMessage("");
      setSelectedChainId(null);
      setDisableButton(false);
      setSelectedVerifierAddress("");
      setStatus(ModalStatus.PreConfirm);
    },

    valid: true,
    status: status,
    children:
      task.proof_submit_mode === ProofSubmitMode.Auto
        ? verifyAutoSubmitOptions
        : verifyOptions,
    message: message,
    confirmLabel: "Confirm",
  };
  return ModalCommon(props);
}
