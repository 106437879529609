import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setEndpointList } from "../data/endpoint";
import { Container, Form, Modal } from "react-bootstrap";
import { storageKey, selectEndpointList } from "../data/endpoint";
import { TextInput } from "../components/Inputs";
import {
  ModalCommon,
  ModalCommonProps,
  ModalStatus,
  WaitingForResponseBar,
} from "./base";
export default function AddCustomURL() {
  let content = (
    <>
      <Container>
        <p>For any enquiries about our ZKWasm platform and service</p>
        <p>
          Please contact us at{" "}
          <a
            href="mailto:
          info@delphinuslab.com"
          >
            info@delphinuslab.com
          </a>
        </p>
      </Container>
    </>
  );

  let props: ModalCommonProps = {
    btnLabel: (
      <div className="subscribe-button">
        <span>Contact Us</span>
      </div>
    ),
    title: "Contact Us",
    childrenClass: "",

    children: content,
    valid: true,
    message: "",
    status: ModalStatus.PreConfirm,
    confirmLabel: "Confirm",
  };
  return ModalCommon(props);
}
