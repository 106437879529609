// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics {
  background-color: #295f7f;
  border-radius: 10px;
  color: #93bac7;
  font-family: Inter; }
  .statistics .stat-value {
    color: white;
    font-weight: 400;
    font-size: 12px; }
  .statistics .stat-title {
    font-weight: 500;
    font-size: 14px; }
  .statistics i.bi {
    margin-right: 4px;
    font-size: 30px;
    color: white; }
`, "",{"version":3,"sources":["webpack://./src/components/Statistics/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,kBAAkB,EAAA;EAJpB;IAMI,YAAY;IACZ,gBAAgB;IAChB,eAAe,EAAA;EARnB;IAWI,gBAAgB;IAChB,eAAe,EAAA;EAZnB;IAeI,iBAAiB;IACjB,eAAe;IACf,YAAY,EAAA","sourcesContent":[".statistics {\n  background-color: #295f7f;\n  border-radius: 10px;\n  color: #93bac7;\n  font-family: Inter;\n  .stat-value {\n    color: white;\n    font-weight: 400;\n    font-size: 12px;\n  }\n  .stat-title {\n    font-weight: 500;\n    font-size: 14px;\n  }\n  i.bi {\n    margin-right: 4px;\n    font-size: 30px;\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
