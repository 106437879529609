import React, { ChangeEvent, FC, useRef, useState } from "react";
import "./style.scss"; // Import the generated CSS file

// Define the component's props interface
interface FileInputProps {
  onFileSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  buttonText?: string;
  accept?: string;
  multiple?: boolean;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
  infoProps?: React.HTMLAttributes<HTMLDivElement>;
}

// The reusable input component
const FileInput: FC<FileInputProps> = ({
  onFileSelect,
  buttonText = "Choose File",
  accept = "*/*",
  multiple = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] =
    useState<string>("No file chosen");

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files.length > 0) {
        setSelectedFileName(event.target.files[0].name);
      } else {
        setSelectedFileName("No file chosen");
      }
    }
    onFileSelect(event);
  };

  return (
    <div className="file-input">
      <input
        ref={inputRef}
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleFileChange}
      />
      <button type="button" onClick={handleButtonClick}>
        {buttonText}
      </button>
      <div className="file-info" onClick={handleButtonClick}>
        {selectedFileName}
      </div>
    </div>
  );
};

export default FileInput;
