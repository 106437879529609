// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-area-wrapper {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  margin: 8px 0; }
  .text-area-wrapper textarea {
    outline: none;
    border: none;
    background-color: transparent;
    color: white; }
`, "",{"version":3,"sources":["webpack://./src/components/Inputs/TextArea/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,aAAa,EAAA;EAJf;IAMI,aAAa;IAGb,YAAY;IAEZ,6BAA6B;IAE7B,YAAY,EAAA","sourcesContent":[".text-area-wrapper {\n  padding: 16px 20px;\n  display: flex;\n  flex-direction: column;\n  margin: 8px 0;\n  textarea {\n    outline: none;\n\n    //border: 2px solid #acd1d5;\n    border: none;\n\n    background-color: transparent;\n\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
