// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState, useEffect } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { useAppSelector } from "../app/hooks";
import "./style.scss";
import { ModalCommon, ModalCommonProps, ModalStatus } from "./base";

import { useGetImageByHashQuery } from "../data/apiSlice";
import { getChainConfigFromChainId } from "../utils/address";

export interface ImageInfoProps {
  md5: string;
}

export function ImageInfoModal(info: ImageInfoProps) {
  const [queryStatus, setQueryStatus] = useState<boolean>(true);

  function queryImage() {
    setQueryStatus(false);
  }

  const {
    data: image,
    isLoading: loadingImage,
    isSuccess: loadedImage,
  } = useGetImageByHashQuery(info.md5, {
    skip: queryStatus,
  });

  let children = (
    <>
      <Container>
        <div className="scroll-200 image-info">
          {loadingImage && <div>Loading...</div>}
          {loadedImage && image.deployment.length > 0 ? (
            <>
              <div className="text-end  mb-4 info-title text-white">
                <span className="label-text">Chain ID</span>
                <span className="label-text">Chain Name</span>
                <span className="label-text">Contract Address</span>
              </div>
              {image.deployment.map((deploy) => {
                let config = getChainConfigFromChainId(deploy.chain_id);
                return (
                  <div key={deploy.address} className="my-2 info-row ">
                    <span>{deploy.chain_id}</span>
                    <span className="text-capitalize">
                      {config?.chain_name}
                    </span>
                    <a
                      className=" address"
                      href={
                        config?.block_explorer_url +
                        "/address/" +
                        deploy.address
                      }
                      target="_blank"
                    >
                      {deploy.address}
                    </a>
                  </div>
                );
              })}
            </>
          ) : (
            <div>Not Deployed</div>
          )}
        </div>
      </Container>
    </>
  );
  let props: ModalCommonProps = {
    btnLabel: <i className="bi bi-eye"></i>,
    title: "Deployment Info",
    childrenClass: "",
    handleShow: function (): void {
      queryImage();
    },
    valid: true,
    status: ModalStatus.PostConfirm,
    children: children,
    message: "",
  };
  return ModalCommon(props);
}
