import React from 'react';
import { Card } from 'react-bootstrap';
import './style.scss';

interface CustomCardProps {
  header: string;
  cardProps?: React.HTMLAttributes<HTMLDivElement>;
  headerProps?: React.HTMLAttributes<HTMLDivElement>;
  bodyProps?: React.HTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = ({
  header,
  cardProps,
  headerProps,
  bodyProps,
  children,
}) => {
  return (
    <Card className="custom-card" {...cardProps}>
      <Card.Header className="custom-header" {...headerProps}>
        {header}
      </Card.Header>
      <Card.Body className="custom-body" {...bodyProps}>
        {children}
      </Card.Body>
    </Card>
  );
};

export default CustomCard;
